import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { useAppSelector } from '../../app/hooks/redux';
import { selectDashboard } from '../../app/store/slice/dashboard';

import Orders from './Orders';
import BalanceCreate from '../../containers/Dashboard/Balance';
import Payments from './Payments';
import Profile from './Profile';
import Navigation from '../../containers/Dashboard/Navigation';
import Sidebar from '../../containers/Dashboard/Sidebar';

function Dashboard (props: any) {

    const sidebarClassName = useAppSelector(selectDashboard);

    return (
        <div className={`ltr main-body app sidebar-mini ${sidebarClassName}`}>
            <div className="horizontalMenucontainer">
                <div className="page">
                    <div className="open">
                        <Navigation />
                        <Sidebar />
                    </div>
                    <div className="main-content app-content" >
                        <div className="side-app">
                            <div className="main-container container-fluid">
                                <Routes>
                                    <Route path="/profile/*" element={<Profile />} />
                                    <Route path="/orders/*" element={<Orders />} /> 
                                    <Route path="/balance" element={<BalanceCreate/>} /> 
                                    <Route path="/payments/*" element={<Payments />} />
                                </Routes>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
