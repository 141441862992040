import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../app/hooks/redux';
import { cleanPaymentIntent } from '../../../app/store/slice/payments';

import PaymentsComponent from '../../../components/Payments';

function Payments () {

    const dispatch = useAppDispatch();

    useEffect(function () {
        dispatch(cleanPaymentIntent());
    }, [dispatch]);

    return (
        <PaymentsComponent />
    );
};

export default Payments;
