import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks/redux';
import { signUp } from '../../app/store/slice/auth/asyncTasks';
import { SignUpPayload } from '../../models/auth';

import RegisterComponent from '../../components/Auth/Register';

function Register () {

    const navigate = useNavigate()
    const dispatch = useAppDispatch();

    function onSubmit (formValues: SignUpPayload) {
        dispatch(signUp(formValues));
        navigate("/auth/confirmation");
    };

    return (
        <RegisterComponent onSubmit={onSubmit} />
    );
};

export default Register;
