import React, { useState } from 'react';

import Confirmation from '../../Modal/Confirmation';

function ConfirmForm(props: any) {

  const [showModal, setShowModal] = useState(false);

  const { onSubmitWithBalance, total } = props;

  function handleOnSubmit(e: any) {

    e.preventDefault();
    setShowModal(true);
  }

  function handleOnSubmitModal() {
    onSubmitWithBalance();
  }

  function onDismiss() {
    setShowModal(false);
  };

  return (
    <form className="pt-5 pb-5" id="payment-form" onSubmit={handleOnSubmit}>
      <dl className="dlist-align">
        <h3>Total:</h3>
        <dd className="text-end ms-auto tx-40">
          MXN
          <strong>${total}</strong>
        </dd>
      </dl>
      <button className="btn btn-success" id="submit" >
        <span id="button-text">
          {"Confirmar pago"}
        </span>
      </button>
      <Confirmation
        showModal={showModal}
        title={'¿Estás seguro que quieres proceder?'}
        firstButton={'Continuar'}
        content={'Esta acción no puede ser revertida'}
        onDismiss={onDismiss}
        onSubmit={handleOnSubmitModal}
      />
    </form>
  );
};

export default ConfirmForm;
