export const quotationsTypes: any = {
  'quick_time': { 
    type: 'quick_time',
    text: "QUICK TIME",
    phrase: "¡Cuando la entrega la tenías el día de ayer!",
    conditions: "aplica en cortes dias habiles",
    backgroundColorClassName: "background-quick-color",
  },
  'plus_time': {
    type: 'plus_time',
    text: "PLUS TIME",
    phrase: "¡Cuando el tiempo de entrega es lo más valioso!",
    conditions: "aplica en cortes dias habiles",
    backgroundColorClassName: "background-plus-color",
  },
  'normal_time': {
    type: 'normal_time',
    text: "NORMAL",
    phrase: "¡Cuando el precio es importante para ti!",
    conditions: "aplica en cortes dias habiles",
    backgroundColorClassName: "background-normal-color",
  },
};
