import React from 'react';
import { Link } from 'react-router-dom';
import "./styles.css";

import Metales from "../../assets/img/backgrounds/laser-metales.jpg";
import Plasticos from "../../assets/img/backgrounds/laser-plasticos.jpg";
import Tubo from "../../assets/img/backgrounds/laser-tubo.jpg";
import CNC from "../../assets/img/backgrounds/router-cnc.jpg";

function Selecting() {
  return (
    <div className="scrollable-section-home">
      <div className="img-col-home">
          <h1 className="text-muted fw-medium fs-1">¡Adelante, estamos listos!</h1>
      </div>
      <div className="image-container-home row">
        <div className='img-col-home'>
          <Link to={'dashboard/orders/create'}>
            <img src={Metales}/>
            <p className='card-title-home'>Láser Metales</p>
          </Link>
        </div>
        <div className='img-col-home'>
          <Link to={'dashboard/orders/create'}>
            <img src={Plasticos}/>
            <p className='card-title-home'>Láser <br></br>Plásticos</p>
          </Link>
        </div>
        <div className='img-col-home'>
          <Link to={'dashboard/orders/create'}>
            <img src={CNC}/>
            <p className='card-title-home'>Router <br></br> CNC</p>
          </Link>
        </div>
        <div className='img-col-home'>
          <Link to={'dashboard/orders/create'}>
            <img src={Tubo}/>
            <p className='card-title-home'>Láser Tubo</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Selecting;
