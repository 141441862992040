import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppThunkDispatch, useAppSelector, useAppDispatch } from '../../../app/hooks/redux';

import { fetchClientSecretPaymentIntent } from '../../../app/store/slice/payments/asyncTasks';
import { setStripeError, selectPayments, cleanPaymentIntent } from '../../../app/store/slice/payments';
import { updateUser } from '../../../app/store/slice/user/asyncTasks';
import { selectUsers } from '../../../app/store/slice/user';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { stripeConfig } from '../../../config/stripe';

import { StripeTypes } from '../../../models/stripe';

import BalanceComponent from '../../../components/Balance';

const stripePublishableKey = loadStripe(stripeConfig.publishableKey);

function BalanceCreate () {

    const [amount, setAmount] = useState(0);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const dispatchThunk = useAppThunkDispatch();
    const { payment } = useAppSelector(selectPayments);
    const { user } = useAppSelector(selectUsers);

    useEffect(function () {
        return function () {
            if (payment) {
                dispatch(cleanPaymentIntent());
            };
        };
    });

    function createPaymentIntent (values: any) {
        setAmount(values.amount);
        dispatchThunk(fetchClientSecretPaymentIntent(Number(values.amount)));
    };

    async function onSubmit (values: any) {

        try {
            const newBalance: Number = user.balance ? user.balance + Number(amount) : Number(amount);
        
            const balance = {
                balance: newBalance,
            }

            const { stripe, elements } = values;

            await stripe.confirmPayment({
                elements,
                redirect: "if_required",
            });

            await dispatchThunk(updateUser(balance));

            navigate("/dashboard/payments");
            
        } catch (error: any) {
            if (error.type === "card_error" || error.type === "validation_error") {
                dispatch(setStripeError(error.message));
                dispatchThunk(updateUser({ balance: 0 }));
            } else {
                dispatch(setStripeError("An unexpected error occurred."));
                dispatchThunk(updateUser({ balance: 0 }));
            }
        };
       
    };

    const options: StripeTypes = {
        clientSecret: payment?.client_secret,
        appearance: {
        theme: "stripe",
        variables: {
            colorPrimary: '#008b8b'
          },
        },
    };

    return (
        <Fragment>
            {
                //!payment || status === "idle" ?
                !payment ?
                    <BalanceComponent onSubmit={onSubmit} amount={amount} payment={payment} createPaymentIntent={createPaymentIntent} />
                :
                    <Elements stripe={stripePublishableKey} options={options}>
                        <BalanceComponent onSubmit={onSubmit} amount={amount} payment={payment} createPaymentIntent={createPaymentIntent} />
                    </Elements>
            }
        </Fragment>
    );
};

export default BalanceCreate;
