import { DISCOUNT_TYPE, PAYMENT_TYPE } from './constants';

function PaymentDetail (props: any) {
  
  const { quotation, user, order, amountLeft, extras, extrasSum, useBalance } = props;

  const discount = amountLeft <= 0 ? quotation.price + extrasSum : quotation.price - amountLeft;
  const total = amountLeft > 0 ? amountLeft : quotation.price;
  const quotationWithExtras = quotation.price + extrasSum;
  const discountType = useBalance.isUse ? DISCOUNT_TYPE : "No especificado";
  const paymentType = quotation.type ? quotation.type : PAYMENT_TYPE;

  function renderExtraOptions(extras: any) {
    return extras.map((extra: any, index: number) => {
      const address = `${user.fa_street} ${user.fa_number} ${user.fa_suburb} ${user.fa_zip_code} ${user.fa_city} ${user.fa_state}`;
      return (
        <div key={index} className="card border rounded extra-container border-gray">
            <div className="card-body">
              <div className="checkbox">
                  <div className="d-flex">
                  <label
                    className="mx-2 form-label"
                    htmlFor={extra.description}
                  >
                    {extra.description === "extra_material_reception" ? "Recolección de material a tu domicilio ZMG 15km" : "Entrega del corte a tu domicilio ZMG 15km"}
                  </label>
                  </div>
              </div>
              <dl className="dlist-align mt-3">
                <dt className="">Precio: </dt>
                <dd className="text-end ms-auto">${extra.price}</dd>
              </dl>
              <p><span className="font-weight-bold">Dirección: </span>{address}</p>
            </div>
        </div>
      );
    });
  }

  return (
    <div className="card cart-details p-4">
      <h3 className="text-muted font-weight-bold">RESUMEN DE TU ORDEN DE CORTE</h3>
      <div className="card-body">
        <div className="row mb-5">
          <div className="col-4">
            <p>ID de Cliente</p>
            <p className="text-muted">{order.client_id}</p>
          </div>
          <div className="col-4">
            <p>No. de Cotización</p>
            <p className="text-muted">{quotation.quotation_id}</p>
          </div>
        </div>
        <div className="row border-bottom">
          <div className="col">
            {/* <p>Recolección de material</p> */}
            <p>Corte</p>
            <p>Entrega en planta</p>
          </div>
          <div className="col">
            {/* <p className="font-weight-bold">9 de octubre 2023</p> */}
            <p className="font-weight-bold">{quotation.machine_scheduled_date}</p>
            <p className="font-weight-bold">{quotation.due_date}</p>
          </div>

          {
            !extras || extras.length <= 0 ?
            <div className="border my-5">
              <h3>No hay Servicios Opcionales</h3>
            </div>
            :
              <div className="border my-5">
                <h3>Servicios Opcionales</h3>
                {renderExtraOptions(extras)}
              </div> 
          }
        </div>
        <dl className="dlist-align mt-3">
          <dt className="">Total a pagar: </dt>
          <dd className="text-end ms-auto">${quotationWithExtras}</dd>
        </dl>
        {
          useBalance.isUse ?
          <div>
            <dl className="dlist-align">
              <dt>Descuento: <p className="text-muted">{discountType}</p></dt>
              <dd className="text-end text-danger ms-auto">-${discount}</dd>
            </dl>
          </div>
          :
            null
        }
        
      </div>
      <div className="card-footer">
        <div className="column">
        <dl className="dlist-align">
          <h3>Faltante por pagar:</h3>
          <dd className="text-end  ms-auto tx-40">
            MXN
            {
              useBalance.isUse ?  
                <strong>${ amountLeft <= 0 ?  0 : amountLeft }</strong>
              :
                <strong>${ quotationWithExtras }</strong>
            }
          </dd>
        </dl>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetail;
