import React from 'react';

function Footer() {
    return (
        <div className="bg-dark">
            <footer className='row pt-3 justify-content-evenly'>
                <div className='col-xs-12'>
                    <p className="text-white text-center">© 2024 Company, Inc</p>
                </div>
                
                <div className="col-xs-12">
                    <p className="text-white text-center">Blvd. Calle 7777, Col. Colonia, C.P. 11111 Municipio, Estado</p>
                </div>
                
            </footer>
        </div>
    );
};

export default Footer;
