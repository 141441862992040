import React, { useState } from 'react';
import type { ChangeEvent } from 'react';

import Header from '../../Dashboard/Header';
import PaymentForm from '../../Payments/PaymentForm';
import PaymentDetail from './PaymentDetail';
import ConfirmForm from './ConfirmForm';
import TransferInfo from './TransferInfo';


function OrderPaymentComponent(props: any) {

    const { quotation, amountLeft, useBalance, extras, extrasSum, user, order, onSubmit, onSubmitWithBalance } = props;
    const [selectedOption, setSelectedOption] = useState("payment");

    const total = useBalance.isUse ? amountLeft <= 0 ?  0 : amountLeft : quotation.price + extrasSum;

    const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value);
      };

    function renderTransferOrPayment(option: string) {
        if (option === "transfer") {
            return (
                <TransferInfo total={total} />
            );
        }
        return (
            <div className="card-body p-5">
                <div className="main-content-label mg-b-5">Ingresa los datos de tu tarjeta</div>
                <PaymentForm
                    total={total}
                    onSubmit={onSubmit}
                />
                
            </div>
        );
    }

    return (
        <div>
            <Header title="PAGO" />
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card">
                        {
                            useBalance && useBalance.isUse && amountLeft <= 0 ?
                                null
                            :
                                <div className="container m-4">
                                    <p className="h2">Información de pago</p>
                                    <div className="row mg-t-10">
                                        <div className="col">
                                            <label className="rdiobox m-3">
                                                <input name="rdio" value="payment" type="radio" checked={selectedOption === "payment"} onChange={handleOptionChange} />
                                                <span className="mx-2">Tarjeta de crédito</span>
                                            </label>
                                            <label className="rdiobox m-3">
                                                <input name="rdio" value="transfer" type="radio" checked={selectedOption === "transfer"} onChange={handleOptionChange} />
                                                <span className="mx-2">Transferencia</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                        }
                        {
                            useBalance && useBalance.isUse && amountLeft <= 0 ?
                                <div className="card-body p-5">
                                    <div className="main-content-label mg-b-5">Confirma los detalles</div>
                                    <ConfirmForm
                                        onSubmitWithBalance={onSubmitWithBalance}
                                        total={total}
                                    />
                                </div>
                                :
                                renderTransferOrPayment(selectedOption)
                        }
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <PaymentDetail
                        quotation={quotation}
                        user={user}
                        order={order}
                        amountLeft={amountLeft}
                        extras={extras}
                        extrasSum={extrasSum}
                        useBalance={useBalance}
                    />
                </div>
            </div>
        </div>
    );
};


export default OrderPaymentComponent;
