import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import RegisterForm from './RegisterForm';

import './style.css';

function RegisterComponent(props: any) {
    const { onSubmit } = props;
    return (
        <div className="row">
            <div className="page col card card-sigin-main justify-content-end">
                <div className="container m-5">
                    <div className="card d-flex flex-column justify-content-end">
                            <div className="d-flex">
                                <Link to="#">
                                    <img
                                        src={require("../../../assets/img/brand/favicon.png")}
                                        className="sign-favicon ht-40"
                                        alt="logo"
                                    />
                                </Link>
                            </div>
                            <div className="main-card-signin m-5">
                                <div className="d-flex flex-column">
                                    <div className="align-self-end">
                                    <div className="main-signup-header">
                                    <div className="d-flex justify-content-end">
                                        <h1 className="font-weight-bold text-muted display-5">Estamos listos, solo ayúdanos con tus datos</h1>
                                    </div>
                                    <div className="d-inline-flex justify-content-end">
                                    <div className="m-5 border-bottom-0">
                                        <div className="">
                                            {/* {err && <Alert variant="danger">{err}</Alert>} */}
                                            <RegisterForm onSubmit={onSubmit} />
                                        </div>
                                    </div>
                                </div>
                                </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <div className="d-none d-sm-block page col background-image-register"></div>
        </div>
    );
};

export default RegisterComponent;
