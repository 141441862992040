import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from '../..';
import { QuotationsState } from '../../../../models/quotations';
import { fetchQuotations, fetchQuotation, updateQuotation } from './asyncTasks';

const initialState: QuotationsState = {
  quotations: [],
  quotation: undefined,
  extras: [],
  useBalance: {
    quotationId: '',
    isUse: false
  },
  status: 'idle',
  message: ''
};

export const quotationsSlice = createSlice({
  name: 'quotations',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    cleanQuotation: function (state) {
      state.status = "idle";
      state.message = "";
      state.quotation = undefined;
      state.extras = [];
      state.useBalance = {
        quotationId: '',
        isUse: false
      };
    },
    cleanUseBalance: function (state) {
      state.useBalance = undefined;
    },
    setUseBalance: function (state, action: PayloadAction<any>) {
      state.useBalance = action.payload;
    },
    addExtra: function(state, action: PayloadAction<any>) {
      state.extras.push(action.payload);
    },
    removeExtra: function(state, action: PayloadAction<any>) {
      state.extras = state.extras.filter((extra) => {
        if (extra.quotation_id === action.payload.quotation_id) {
          if (extra.description === action.payload.description) {
            return false;
          }
        }

        return true;
      });
    },
    removeAllUnusedExtras: function(state, action: PayloadAction<any>) {
      state.extras = state.extras.filter((extra) => extra.quotation_id === action.payload.quotationId);
    },
  },
  extraReducers: function (builder) {
    builder
      .addCase(fetchQuotations.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(fetchQuotations.fulfilled, function (state, action){
        state.quotations = action.payload.data.items;
        state.status = "fulfilled";
      })
      .addCase(fetchQuotations.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      .addCase(fetchQuotation.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(fetchQuotation.fulfilled, function (state, action){
        state.quotation = action.payload.data;
        state.status = "fulfilled";
      })
      .addCase(fetchQuotation.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Update quotation
      .addCase(updateQuotation.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(updateQuotation.fulfilled, function (state, action){
        state.quotation = action.payload.data;
        state.status = "fulfilled";
      })
      .addCase(updateQuotation.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
  },
});

export const { cleanQuotation, cleanUseBalance, setUseBalance, addExtra, removeExtra, removeAllUnusedExtras } = quotationsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export function selectQuotations (state: RootState) {return state.quotations};

export default quotationsSlice.reducer;
