import React from "react";
import { Link } from "react-router-dom";

import Header from "../Dashboard/Header";
import './styles.css';

function Profile(props: any) {

  const { rfc, cfdi, tax_regime, company_name, email, fa_city, fa_locality, fa_number, fa_state, fa_street, fa_suburb, fa_zip_code, phone_number } = props.user;

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <Header title="PERFIL" />
      {/* <!-- /breadcrumb --> */}

      <div className="row">
        <div className="col col-lg-6 colmd-6">
          <div className="card custom-card customs-cards">
            <div className="card-body d-md-flex bg-white">
              <div className="">
                <span className="profile-image pos-relative">
                  <img
                    className="br-5"
                    alt=""
                    src={require("../../assets/img/faces/profile.png")}
                  />
                </span>
              </div>
              <div className="my-md-auto mt-4 prof-details">
                <h4 className="font-weight-semibold ms-md-4 ms-0 mb-1 pb-0">
                  {company_name}
                </h4>

                <p className="text-muted ms-md-4 ms-0 mb-2">
                  <span>
                    <i className="fa fa-phone me-2"></i>
                  </span>
                  <span className="font-weight-semibold me-2">WhatsApp:</span>
                  <span>{phone_number}</span>
                </p>
                <p className="text-muted ms-md-4 ms-0 mb-2">
                  <span>
                    <i className="fa fa-envelope me-2"></i>
                  </span>
                  <span className="font-weight-semibold me-2">Email:</span>
                  <span>{email}</span>
                </p>
                {
                  props.user.balance === undefined ?
                    null
                    :
                    <p className="text-muted ms-md-4 ms-0 mb-2">
                      <span>
                        <i className="fa fa-hourglass-half me-2"></i>
                      </span>
                      <span className="font-weight-semibold me-2">Saldo disponible:</span>
                      <span>${props.user.balance}</span>
                    </p>
                }
                <Link to={'/dashboard/balance'} className="btn btn-success ms-md-4 ms-0 mb-2">Agregar saldo</Link>
              </div>
            </div>
          </div>

          <span className=" py-0 ">
            <div className="profile-tab tab-menu-heading border-bottom-0 ">
              <div className="nav profile-tabs main-nav-line tabs-menu profile-nav-line bg-white mb-4 border-0 br-5 mb-0	 nav nav-pills">
                <div className="me-1 nav-item">
                  <a role="tab" data-rr-ui-event-key="About" id="left-tabs-example-tab-About" aria-controls="left-tabs-example-tabpane-About" aria-selected="true" className=" mb-2 mt-2 nav-link active" href="#about">Información</a>
                </div>
              </div>
              <div className=" row-sm  row">
                <div className="col-lg-12 col-md-12">
                  <div className="custom-card main-content-body-profile">
                    <div className="tab-content">
                      <div role="tabpanel" id="left-tabs-example-tabpane-About" aria-labelledby="left-tabs-example-tab-About" className="fade tab-pane active show">
                        <div className="main-content-body tab-pane  active" id="about">
                          <div className="card">
                            <div className=" p-0 border-0 p-0 rounded-10 card-body">
                              <div className="p-4">
                                <h4 className="tx-15 text-uppercase mb-3">Datos de facturación</h4>
                                <div className="m-t-30">
                                  <div className="p-t-10">
                                    <div className="d-sm-flex">
                                    
                                      <div className="mg-sm-r-20 mg-b-10">
                                        <div className="media">
                                          <div className="media-body">
                                            <span><b>#</b></span>
                                            <div>{fa_number}</div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mg-sm-r-20 mg-b-10">
                                        <div className="media">
                                          <div className="media-body">
                                            <span><b>Calle</b></span>
                                            <div>{fa_street}</div>
                                          </div>
                                        </div>
                                      </div>  

                                      <div className="mg-sm-r-20 mg-b-10">
                                        <div className="media">
                                          <div className="media-body">
                                            <span><b>Colonia</b></span>
                                            <div>{fa_suburb}</div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mg-sm-r-20 mg-b-10">
                                        <div className="media">
                                          <div className="media-body">
                                            <span><b>C.P.</b></span>
                                            <div>{fa_zip_code}</div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mg-sm-r-20 mg-b-10">
                                        <div className="media">
                                          <div className="media-body">
                                            <span><b>Ciudad</b></span>
                                            <div>{fa_city}</div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mg-sm-r-20 mg-b-10">
                                        <div className="media">
                                          <div className="media-body">
                                            <span><b>Estado</b></span>
                                            <div>{fa_state}</div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                
                                  <div className="p-t-10">
                                    <div className="d-sm-flex">

                                        <div className="mg-sm-r-20 mg-b-10">
                                          <div className="media">
                                            <div className="media-body">
                                              <span><b>RFC</b></span>
                                              <div>{rfc}</div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="mg-sm-r-20 mg-b-10">
                                          <div className="media">
                                            <div className="media-body">
                                              <span><b>Municipio</b></span>
                                              <div>{fa_locality}</div>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    </div>

                                    <div className="p-t-10">
                                      <div className="d-sm-flex">

                                        <div className="mg-sm-r-20 mg-b-10">
                                          <div className="media">
                                            <div className="media-body">
                                              <span><b>Régimen Fiscal</b></span>
                                              <div>{tax_regime}</div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="mg-sm-r-20 mg-b-10">
                                          <div className="media">
                                            <div className="media-body">
                                              <span><b>Uso de CFDI</b></span>
                                              <div>{cfdi}</div>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                   
                                </div>
                              </div>
                              <div className="border-top">
                              </div>
                              <div className="border-top">
                              </div>
                              {/* <div className="p-4">
                                <label className="main-content-label tx-13 mg-b-20">Contacto</label>
                                <div className="d-sm-flex">
                                  <div className="mg-sm-r-20 mg-b-10">
                                    <div className="main-profile-contact-list">
                                      <div className="media">
                                        <div className="media-icon bg-primary-transparent text-primary">
                                          <i className="icon ion-md-phone-portrait"></i>
                                        </div>
                                        <div className="media-body">
                                          <span>Mobile</span>
                                          <div>+{phone_number}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                              <div className="border-top"></div>
                              <div className="p-4">
                                <label className="main-content-label tx-13 mg-b-20">Configuración</label>
                                <div className="d-lg-flex">
                                  <div className="mg-md-r-20 mg-b-10">
                                    <Link to={'/dashboard/profile/change-password'} className= "btn btn-primary ms-md-4 ms-0 mb-2">
                                      Cambiar contraseña
                                    </Link>
                                  </div>
                                  <div className="mg-md-r-20 mg-b-10">
                                    <Link to={'/dashboard/profile/update'} className="btn btn-info ms-md-4 ms-0 mb-2">
                                      Actualizar perfil
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </div>
        <div className="col col-lg-6 colmd-6 d-flex justify-content-center align-items-center flex-column">
          <h1 style={{color: "#6c757d"}}>Sueña en grande.</h1>
          <div className="img-profile-page mt-5">
            <img className="rounded mx-auto d-block" src={require("../../assets/img/png/world.png")}/>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Profile;
