import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedLoginRoute (props: any) {

    const { user, children } = props;

    if (user.isSigned === true && user.userConfirmed === false) {
        return <Navigate to={"/auth/confirmation"} replace />;
    }

    return children;
};

export default ProtectedLoginRoute;
