import React, { Fragment } from 'react';

import Header from '../../Dashboard/Header';
import OrderQuotationItem from './OrderQuotationItem';
import OrdersCalendar from '../../Calendar/Orders';
import OrderBannerDetail from './OrderBannerDetail';

import './style.css';

import { quotationsTypes } from './constants';

function Quotations(props: any) {

  const { quotations, user, order, useBalance, onSubmit, handleCheckbox, addExtraQuotation, removeExtraQuotation } = props;

  function renderQuotations(quotations: any) {
    return quotations.map(function (quotation: any, i: number) {
      return <OrderQuotationItem
        order={order}
        key={quotation.quotation_id}
        quotation={quotation}
        user={user}
        useBalance={useBalance}
        onSubmit={onSubmit}
        handleCheckbox={handleCheckbox}
        addExtraQuotation={addExtraQuotation}
        removeExtraQuotation={removeExtraQuotation}
        quotationType={quotationsTypes[quotation.quotation_type]}
      />
    });
  };

  return (
    <Fragment>
      <Header title="COTIZACIONES" />
      <div className="row row-sm">
        <div className="col col-lg-12">
          <div className="card mg-b-20">
            <div className="card-body d-flex p-3">
              <div className="main-content-label mb-0 mg-t-8">
                Seleccione una cotización
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col col-md-4 col-sm-6">
          <OrderBannerDetail order={order} />
        </div>
        <div className="col">
          <OrdersCalendar quotations={quotations} isFromCalendarContainer={false} height={'450px'} />
        </div>
      </div>
      {/* <div className="col col-xl-12 col-md-12"> */}
        <div className="row row-sm">
          {renderQuotations(quotations)}
        </div>
      {/* </div> */}

    </Fragment>
  );
}

export default Quotations;
