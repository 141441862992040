import React from 'react';
import { Link } from 'react-router-dom';

function NavigateButton (props: any) {

    const { user } = props;

    if (user.isSigned === false && user.userConfirmed === false) {
        return (
        <form className="d-flex" role="search">
        <Link className="btn me-2 btn-info" to={"/auth/login"}>Inicia Sesión</Link>
        <Link className="btn me-2 btn-primary" to={"/auth/register"}>Registrate</Link>
      </form>);
    }

    return (
    <Link className="btn me-2 btn-info" to={"/dashboard/orders"}>Ir a Dashboard</Link>
  );
};

export default NavigateButton;

