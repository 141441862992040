import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Input from '../../Form/Input';

import { requireField, auth, errorTypes } from '../../../utils/formValidations';

function LoginForm (props: any) {

    const { onSubmit } = props;
    const { register, handleSubmit, formState: { errors } } = useForm();

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Input 
                type={"text"} 
                name={"email"} 
                validation={{ required: requireField, pattern: auth.emailFormat }} 
                label={"Email"}
                imgSrc={require("../../../assets/img/png/brand/email.png")}
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese email' }, { type: errorTypes.pattern, message: 'Formato de email inválido' }]} 
                register={register} 
                errors={errors} 
            />
            <Input 
                type={"password"} 
                name={"password"} 
                validation={{ required: requireField }} 
                label={"Password"}
                imgSrc={require("../../../assets/img/png/brand/password.png")}
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese contraseña' }]} 
                register={register} 
                errors={errors} 
            />
            <div className="main-signin-footer text-center m-3">
            <p><Link to={"/auth/forgot-password-send-code"} className="mb-3">¿Olvidaste tu contraseña?</Link></p>
            </div>
            <button type="submit" className="btn btn-primary btn-block btn">Inicia sesión</button>
            <div className="main-signin-footer text-center mt-5">
                <Link to={`${process.env.PUBLIC_URL}/auth/register`} className="link-create-account"> Crea una cuenta</Link>
            </div>
        </form>
    );
};

export default LoginForm;
