export const requireField = true;
export const minNum = 1;
export const minNumFloat = 0.01;
export const stepFloat = 0.01;


export const errorTypes = {
    required: 'required',
    pattern: 'pattern',
    validate: 'validate',
    min: "min"
};

export const auth = {
    phoneFormat: /^[0-9]{10}$/g,
    emailFormat: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    passwordFormat: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[#$@!%&*?.,+]).{8,20}$/,
    confirmationCodeFormat: /^[0-9]{6}$/g,
};
