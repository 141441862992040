import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppThunkDispatch, useAppSelector } from '../../../../app/hooks/redux';
import { selectOrders } from '../../../../app/store/slice/orders';
import { fetchOrder } from '../../../../app/store/slice/orders/asyncTasks';
import { createOrder } from '../../../../app/store/slice/orders/asyncTasks';

import QuickOrdersFormComponent from '../../../../components/Orders/QuickOrderCreation';

function QuickOrdersCreation () {

    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useAppThunkDispatch();
    const { order } = useAppSelector(selectOrders);


    useEffect(function (){
        dispatch(fetchOrder(params.id));
    }, [dispatch, params]);

    
    async function onSubmit (formValues: any) {
        await dispatch(createOrder({
            client_email: order.client_email,
            client_company_name: order.client_company_name,
            cut_type: formValues.cut_type,
            cut_count: formValues.cut_count,
            material_type: formValues.material_type,
            material_count: formValues.material_count,
            thickness: formValues.thickness,
            width: formValues.width,
            length: formValues.length,
            notes: formValues.notes,
            order_file_path: order.order_file_path
        }));
        navigate("/dashboard/orders");        
    }; 

    return (
        <div>
            <QuickOrdersFormComponent onSubmit={onSubmit} order={order}/>      
        </div>
    )
}
export default QuickOrdersCreation;