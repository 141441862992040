import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { UsersState } from '../../../../models/user';
import { fetchUser, updateUser } from './asyncTasks';

const initialState: UsersState = {
  users: [],
  user: undefined,
  status: 'idle',
  message: ''
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    getUsers: function(state, action: PayloadAction<number>) {}
  },
  extraReducers: function (builder) {
    builder
      // Fetch user
      .addCase(fetchUser.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(fetchUser.fulfilled, function (state, action){
        state.user = action.payload.data;
        state.status = "fulfilled";
      })
      .addCase(fetchUser.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Update user
      .addCase(updateUser.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(updateUser.fulfilled, function (state, action){
        state.user = action.payload.data;
        state.status = "fulfilled";
      })
      .addCase(updateUser.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
  },
});

export const { getUsers } = usersSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export function selectUsers (state: RootState) {return state.users};

export default usersSlice.reducer;
