import React from 'react';
import { useForm } from 'react-hook-form';

import Input from '../../Form/Input';

import { requireField, errorTypes, auth } from '../../../utils/formValidations';

function NewPasswordForm (props: any) {

    const { onSubmit } = props;
    const { register, handleSubmit, formState: { errors }, getValues } = useForm();

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            
            <Input
                type={"password"}
                name={"password"}
                validation={{ required:requireField }}
                label={"Ingresa tu contraseña actual"}
                errorMessages={[{ type: errorTypes.required, message: "Ingrese su contraseña actual" }]}
                register={register}
                errors={errors}
            />
            <Input
                type={"password"}
                name={"newPassword"}
                validation={{
                    required:requireField,
                    pattern: auth.passwordFormat,
                }}
                label={"Ingresa una nueva contraseña"} 
                errorMessages={[{ type: errorTypes.required, message: "Ingrese nueva contraseña con al menos 8 valores alfanuméricos, una mayúscula y un caracter especial" }, { type: errorTypes.pattern, message: "Formato de Password inválido" }]}
                register={register}
                errors={errors}
            />
            <Input
                type={"password"}
                name={"confirmPassword"}
                validation={{
                    required:requireField,
                    validate: function (value: any) { return value === getValues("newPassword") },
                }}
                label={"Confirma tu nueva contraseña"} 
                errorMessages={[{ type: errorTypes.required, message: "Confirme su nueva contraseña por favor" }, { type: errorTypes.validate, message: 'Las contraseñas no coinciden' }]}
                register={register}
                errors={errors}
            />
            <button type="submit" className="btn btn-primary btn-block btn">Cambiar contraseña</button>
            {/* Special links */}
            <div className="mt-4 d-flex text-center justify-content-center mb-2">
            </div>
            <div className="main-signin-footer text-center mt-3">
            </div>
        </form>
    );
};

export default NewPasswordForm;
