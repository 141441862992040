import React from 'react';
import BankAccountsTable from '../../Table/BankAccounts';

import { BANK_ACCOUNTS_MODEL } from '../../Table/utils/models';
import { bankAccounts } from './constants';

function TransferInfo(props: any) {
    const { total } = props;
    return (
        <div className="card-body p-4">
            <BankAccountsTable data={bankAccounts} columns={BANK_ACCOUNTS_MODEL} />
            <dl className="dlist-align px-5">
                <h3>Total:</h3>
                <dd className="text-end ms-auto tx-40">
                    MXN
                    <strong>${total}</strong>
                </dd>
            </dl>
            <p>Presiona el boton y podras enviar tu comprobante a nuestro correo <strong>pagos@corten.mx</strong></p>
            <a href="mailto:pagos@corten.mx" className="btn btn-primary mt-5" >
                <span id="button-text">
                    {"Manda tu comprobante"}
                </span>
            </a>
        </div>
    );
};

export default TransferInfo;
