import React from 'react';
import { Link } from 'react-router-dom';
import NavigateButton from './NavigateButton';


function Header(props: any) {
  const { user } = props;
  return (
    <div className="p-3 br-5 border">
      <nav className="navbar bg-body-tertiary">
        <div className="container-fluid">
          <Link className="navbar-brand" to={'/'} >CORTEN</Link>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to={'#'}>Contáctanos</Link>
            </li>
          </ul>
          <NavigateButton user={user} />
        </div>
      </nav>
    </div>
  );
};

export default Header;
