import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedConfirmRoute (props: any) {

    const { user, children } = props;

    if (user.isSigned === false) {
        return <Navigate to={"/auth/login"} replace />;
    }

    return children;
};

export default ProtectedConfirmRoute;
