import React, { Fragment, useEffect } from 'react';

import { useAppThunkDispatch, useAppSelector } from '../../../../app/hooks/redux';
import { selectOrders } from '../../../../app/store/slice/orders';
import { fetchOrders } from '../../../../app/store/slice/orders/asyncTasks';

import OrdersHistoryTable from '../../../../components/Table/OrdersHistory';
import EmptyInfo from '../../../../components/EmptyInfo';
import Placeholder from '../../../../components/Placeholder';

import { ORDERS_HISTORY_MODEL } from '../../../../components/Table/utils/models';

import img from '../../../../assets/img/png/grafico-de-barras.png'

function OrdersHistory () {

    const dispatchThunk = useAppThunkDispatch();
    const { orders, status } = useAppSelector(selectOrders);
    const ordersHistory = orders.length === 0 ? [] : orders.filter(function (order: any) { return order.status === 'delivered' });

    useEffect(function (){
        if (ordersHistory.length === 0 && status === "idle") {
            dispatchThunk(fetchOrders());
        }
    }, [dispatchThunk]);

    return (
        <Fragment>  
            {
                status === "idle" ?
                    <Placeholder />
                : ordersHistory.length === 0 ?
                    <EmptyInfo img={img}/>
                : 
                    <OrdersHistoryTable data={ordersHistory} columns={ORDERS_HISTORY_MODEL}/>
            }
        </Fragment>
    )
}
export default OrdersHistory;
