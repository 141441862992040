import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Header from '../Dashboard/Header';

import img from '../../assets/img/png/check.png'

function Payment () {
    return (
        <Fragment>
            <Header title="PAGO"/>
            <div className="row">
                <div className="main-error-wrapper wrapper-1 page page-h card">
                    <div className="col-lg-1 m-3">
                        <img className="img-fluid" alt="not found" src={img} />
                    </div>
                    <h2 className="">¡Felicidades!</h2>
                    <h2 className="">El pago se efectuó de manera correcta.</h2>
                    <h6 className="">Te avisaremos el proceso de tu pedido.</h6><Link className="btn btn-primary" to={`${process.env.PUBLIC_URL}/dashboard/orders`}>Ir al inicio</Link>
                </div>
            </div>
        </Fragment>
    );
};

export default Payment;
