import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import ChangePasswordForm from './ChangePasswordForm';

function ChangePasswordComponent (props: any) {

    const { onSubmit } = props;

    return (
        <Fragment>
            <div className="square-box"> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> </div>
            <div className="page bg-primary">
                <div className="page-single">
                    <div className="container" style={{ marginTop: "89px" }} >
                        <div className="row">
                            <div className="col-xl-5 col-lg-6 col-md-8 col-sm-8 col-xs-10 card-sigin-main mx-auto my-auto py-4 justify-content-center">
                                <div className="card-sigin ">
                                    {/* <!-- Demo content--> */}
                                    <div className="main-card-signin d-md-flex">
                                        <div className="wd-100p">
                                            <div className="d-flex mb-4">
                                                <Link to="#">
                                                    <img
                                                        src={require("../../../assets/img/brand/favicon.png")}
                                                        className="sign-favicon ht-40"
                                                        alt="logo"
                                                    />
                                                </Link>
                                            </div>
                                            <div className="">
                                                <div className="main-signup-header">
                                                    <h2>Cambio de contraseña</h2>
                                                    <h6 className="font-weight-semibold mb-4">
                                                        Por favor ingresa tus datos.
                                                    </h6>
                                                    <div className="panel panel-primary">
                                                        <div className=" tab-menu-heading mb-2 border-bottom-0">
                                                            <div className="tabs-menu1">
                                                                {/* {err && <Alert variant="danger">{err}</Alert>} */}
                                                                <ChangePasswordForm onSubmit={onSubmit} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        </Fragment>
    );
};

export default ChangePasswordComponent;
