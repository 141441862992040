import React, { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';

import UserDetail from '../../../containers/Dashboard/User/UserDetail';
import UserUpdate from '../../../containers/Dashboard/User/UserUpdate';
import ChangePassword from '../../../containers/Auth/ChangePassword';

function User () {

    return (
        <Fragment>   
            <Routes>
                <Route path="/" element={<UserDetail />} />
                <Route path="/update" element={<UserUpdate />} />
                <Route path="/change-password" element={<ChangePassword />} />
            </Routes>
        </Fragment>
    );
};

export default User;
