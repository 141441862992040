import React, { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAppDispatch ,useAppSelector } from '../app/hooks/redux';
import { selectAuth, changeStatusToIdle } from '../app/store/slice/auth';

import Login from '../containers/Auth/Login';
import Register from '../containers/Auth/Register';
import Confirmation from '../containers/Auth/Confirmation';
import SendForgotPasswordCode from '../containers/Auth/SendForgotPasswordCode';
import ForgotPassword from '../containers/Auth/ForgotPassword';

import ProtectedConfirmRoute from '../routes/auth/ProtectedConfirmRoute';
import ProtectedLoginRoute from '../routes/auth/ProtectedLoginRoute';
import Success from '../components/Modal/Success';
import Error from '../components/Modal/Error';

function Auth () {

    const dispatch = useAppDispatch();
    const { user, status } = useAppSelector(selectAuth);

    function onDismiss () {
        dispatch(changeStatusToIdle())
    };

    return (
        <Fragment>
            <Routes>
                <Route path="/login" element={
                    <ProtectedLoginRoute user={user}>
                        <Login />
                    </ProtectedLoginRoute>
                } />
                <Route path="/register" element={<Register />} />
                <Route path="/confirmation" element={
                    <ProtectedConfirmRoute user={user}>
                        <Confirmation />
                    </ProtectedConfirmRoute>
                } />
                <Route path="/forgot-password-send-code" element={<SendForgotPasswordCode />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
            </Routes>
            <Success title={"Confirmado"} content={"Operación realizada con éxito"} status={status} onDismiss={onDismiss} />
            <Error title={"Error"} content={"La operación falló"} status={status} onDismiss={onDismiss} />
        </Fragment>
    );
};

export default Auth;
