import React from 'react';
import { Link } from 'react-router-dom';

function OrderBannerDetail(props: any) {

    const { order } = props;

    return (
        <div className="card custom-card">
            <div className="card-body">
                <div className="d-lg-flex">
                    <h6 className="main-content-label mb-1">
                        <span className="d-flex mb-4">
                            <Link to={"/dashboard/orders"}>
                                <img
                                    src={require("../../../assets/img/brand/favicon.png")}
                                    className="sign-favicon ht-40"
                                    alt="logo"
                                />
                            </Link>
                        </span>
                    </h6>

                </div>
                <div className="mb-3">
                    <p className="">
                        <span className="font-weight-bold tx-15">Email:  </span>{order.client_email}
                    </p>
                </div>
                <div className="row row-sm mb-3">
                    <div className="col">
                        <p className="h4">
                            <span className="font-weight-bold">No. Cotización: </span> {order.order_custom_id}
                        </p>
                    </div>
                </div>
                <div className="row row-sm mb-3">
                    <div className="col">
                        <div>
                            <p className="mb-1">
                                <span className="font-weight-bold">Fecha de creación: </span>{order.creation_date.slice(0,10)}
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderBannerDetail;
