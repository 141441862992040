import { createAppAsyncThunk } from '../../../hooks/redux';
import cutDesignApi from '../../../../apis/cutDesignApi';
import { User } from "../../../../models/auth";

import { signOut } from '../auth/asyncTasks';

import { UNAUTHORIZED_ERROR_TYPE, UNAUTHORIZED_ERROR_MESSAGE } from '../../../../utils/types';

export const fetchQuotations = createAppAsyncThunk('quotations/fetchQuotations', async function (id: any, { rejectWithValue, getState, dispatch }) {
    try {

        const user: User = getState().auth.user;

        const res = await cutDesignApi.get(`/orders/${id}/quotations`, { 
            headers: {
                Authorization: user.accessToken,
            }
         });
        return res;
    } catch (err: any) {
        
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
            dispatch(signOut());
        };
        
        return rejectWithValue(err.response.data);
    }
});

export const fetchQuotation = createAppAsyncThunk('quotations/fetchQuotation', async function (values: any, { rejectWithValue, getState, dispatch }) {
    try {

        const user: User = getState().auth.user;

        const { id, quotationId } = values;

        const res = await cutDesignApi.get(`/orders/${id}/quotations/${quotationId}`, { 
            headers: {
                Authorization: user.accessToken,
            }
         });
        return res;
    } catch (err: any) {
        
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
            dispatch(signOut());
        };
        
        return rejectWithValue(err.response.data);
    }
});

export const updateQuotation = createAppAsyncThunk('quotations/updateQuotation', async function (values: any, { rejectWithValue, getState, dispatch }) {
    try {

        const user: User = getState().auth.user;
        const { id, quotationId, quotation } = values;

        const res = await cutDesignApi.put(`/orders/${id}/quotations/${quotationId}`, quotation, { 
            headers: {
                Authorization: user.accessToken,
            }
         });
        return res;
    } catch (err: any) {
       
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
            dispatch(signOut());
        };
        
        return rejectWithValue(err.response.data);
    }
});
