import { createAppAsyncThunk } from '../../../hooks/redux';
import cutDesignApi from '../../../../apis/cutDesignApi';
import { User } from '../../../../models/auth';

import { signOut } from '../auth/asyncTasks';

import { UNAUTHORIZED_ERROR_TYPE, UNAUTHORIZED_ERROR_MESSAGE } from '../../../../utils/types';

export const fetchNotifications = createAppAsyncThunk('notifications/fetchNotifications', async function (_, { rejectWithValue, getState, dispatch }) {
    try {

        const user: User = getState().auth.user;

        const res = await cutDesignApi.get(`/receivers/${user.id}/notifications`, { 
            headers: {
                Authorization: user.accessToken,
            }
         });
        return res;
    } catch (err: any) {
        
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
            dispatch(signOut());
        };
        
        return rejectWithValue(err.response.data);
    }
});

export const updateNotificationsBatch = createAppAsyncThunk('notifications/updateNotificationsBatch', async function (values: any, { rejectWithValue, getState, dispatch }) {
    try {

        const user: User = getState().auth.user;

        const res = await cutDesignApi.put(`/receivers/${user.id}/notifications`, values, { 
            headers: {
                Authorization: user.accessToken,
            }
         });
        return res;
    } catch (err: any) {
        
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
            dispatch(signOut());
        };
        
        return rejectWithValue(err.response.data);
    }
});
