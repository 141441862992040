import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Navbar, Dropdown } from 'react-bootstrap';

import Notifications from './Notifications';

function Navigation (props: any) {
  
  const { onHideShow, markNotificationsSeen, logout, user, notifications } = props;

  return (
    <Fragment>
      <Navbar className="main-header side-header sticky nav nav-item">
        <div className="main-container container-fluid">
          <div className="main-header-left ">
            <div className="app-sidebar__toggle" data-bs-toggle="sidebar">
              <div className="open-toggle" onClick={function () {onHideShow(true)}}>
                <i className="header-icon fe fe-align-left"></i>
              </div>
              <div className="close-toggle" onClick={function () {onHideShow(false)}}>
                <i className="header-icon fe fe-x"></i>
              </div>
            </div>
            <div className="logo-horizontal">
              <Link to={`${process.env.PUBLIC_URL}/dashboard/orders`} className="header-logo">
                <img src={require("../../assets/img/brand/logo.png")} className="mobile-logo logo-1" alt="logo"/>
                <img src={require("../../assets/img/brand/logo-white.png")} className="mobile-logo dark-logo-1" alt="logo" />
              </Link>
            </div>
        
          </div>

          <div className="main-header-right">
          <Navbar.Toggle
            className="navresponsive-toggler d-lg-none ms-auto"
            type="button"
          >
            <span className="navbar-toggler-icon fe fe-more-vertical"></span>
          </Navbar.Toggle>
            <div
              className="mb-0 navbar navbar-expand-lg navbar-nav-right responsive-navbar navbar-dark p-0"
            >
              <Navbar.Collapse className="collapse" id="navbarSupportedContent-4">
                <ul className="nav nav-item header-icons navbar-nav-right ms-auto">

                  {
                    user.balance === undefined ?
                      null
                    :
                    <h5 className="mt-2 mx-5">
                      <span>
                        <i className="fa fa-money-bill me-2"></i>
                      </span>
                      <span className="font-weight-semibold me-2">Saldo disponible:</span>
                      <span>${user.balance}</span>
                    </h5>
                  }
                  
                  <Notifications notifications={notifications} markNotificationsSeen={markNotificationsSeen} />

                  <Dropdown className=" main-profile-menu nav nav-item nav-link ps-lg-2">
                    <Dropdown.Toggle className="new nav-link profile-user d-flex" variant="">
                      <img alt="" src={require("../../assets/img/faces/descarga.png")}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="menu-header-content p-3 border-bottom">
                        <div className="d-flex wd-100p">
                          <div className="ms-3 my-auto">
                            <h6 className="tx-15 font-weight-semibold mb-0">
                              {user.company_name}
                            </h6>
                            <span className="dropdown-title-text subtext op-6  tx-12">
                              {user.email}
                            </span>
                          </div>
                        </div>
                      </div>

                      <Link className="dropdown-item" to={'/dashboard/profile'} >
                        <i className="far fa-user-circle"></i> Perfil
                      </Link>

                      <Link className="dropdown-item" to="#" onClick={function () { logout() }}>
                        <i className="far fa-arrow-alt-circle-left"></i> Cerrar Sesión
                      </Link>

                    </Dropdown.Menu>
                  </Dropdown>
                  
                </ul>
              </Navbar.Collapse>
            </div>
          </div>
          
        </div>
      </Navbar>
    </Fragment>
  );
};

export default Navigation;
