import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Input from '../../Form/Input';

import { requireField, auth, errorTypes } from '../../../utils/formValidations';

function RegisterForm(props: any) {
  const { onSubmit } = props;
  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <Input
        type={"text"}
        name={"custom:company_name"}
        validation={{ required: requireField }}
        label={"Razón social o Nombre"}
        imgSrc={require("../../../assets/img/png/brand/company.png")}
        placeholder="Razón social o Nombre"
        errorMessages={[{ type: errorTypes.required, message: 'Ingrese nombre de la compañia' }]}
        register={register}
        errors={errors}
      />
      <Input
        type={"text"}
        name={"email"}
        validation={{ required: requireField, pattern: auth.emailFormat }}
        label={"Correo electrónico"}
        imgSrc={require("../../../assets/img/png/brand/email.png")}
        placeholder="Correo electrónico"
        errorMessages={[{ type: errorTypes.required, message: 'Ingrese email' }, { type: errorTypes.pattern, message: 'Formato de email inválido' }]}
        register={register}
        errors={errors}
      />
      <Input
        type={"text"}
        name={"custom:phone_number"}
        validation={{ required: requireField, pattern: auth.phoneFormat }}
        label={"Número de teléfono"}
        placeholder="Número de teléfono"
        imgSrc={require("../../../assets/img/png/brand/mobile.png")}
        errorMessages={[{ type: errorTypes.required, message: 'Ingrese un número de teléfono' }, { type: errorTypes.pattern, message: 'El número debe contener 10 dígitos' }]}
        register={register}
        errors={errors}
      />
      <Input
        type={"text"}
        name={"custom:complete_address"}
        validation={{ required: requireField }}
        label={"Direccion completa"}
        imgSrc={require("../../../assets/img/png/brand/document.png")}
        placeholder="Calle, Numero ext, interior, Colonia, C.P. , Ciudad, Estado"
        errorMessages={[{ type: errorTypes.required, message: 'Ingrese direccion' }, { type: errorTypes.pattern, message: 'Ingresar Direccion' }]}
        register={register}
        errors={errors}
      />

      <Input
        type={"password"}
        name={"password"}
        validation={{ required: requireField, pattern: auth.passwordFormat }}
        label={"Contraseña"}
        placeholder="Contraseña"
        imgSrc={require("../../../assets/img/png/brand/document.png")}
        errorMessages={[{ type: errorTypes.required, message: 'Ingrese una contraseña' }, { type: errorTypes.pattern, message: 'La contraseña debe contener una mayúscula, un número, un carácter especial y una longitud mínima de 8 dígitos' }]}
        register={register}
        errors={errors}
      />

      <Input
        type={"password"}
        name={"confirm_password"}
        validation={{
          required: requireField, pattern: auth.passwordFormat, validate: (val: string) => {
            if (watch("password") !== val) {
              return false;
            }
          }
        }}
        label={"Confirmar Contraseña"}
        placeholder="Confirme Contraseña"
        imgSrc={require("../../../assets/img/png/brand/document.png")}
        errorMessages={[{ type: errorTypes.required, message: 'Ingrese una contraseña' }, { type: errorTypes.pattern, message: 'La contraseña debe de ser igual.' }]}
        register={register}
        errors={errors}
      />

      <div className="d-flex flex-row align-items-center justify-content-center">
        <p className="display-6 text-custom-green font-weight-bold m-5">¿Necesitas factura? Llena estos espacios por favor</p>
      </div>
      <div className="form-group">
        <div className="row-sm row">
          <div className="col-sm-6">
            <Input
              type={"text"}
              name={"custom:fa_street"}
              validation={{ required: false }}
              label={"Calle"}
              placeholder="Calle"
              imgSrc={require("../../../assets/img/png/brand/document.png")}
              errorMessages={[{ type: errorTypes.required, message: 'Ingrese el nombre de la calle de su domicilio fiscal' }]}
              register={register}
              errors={errors}
            />
          </div>
          <div className="mg-t-10 mg-sm-t-0 col-sm-6">
            <Input
              type={"number"}
              name={"custom:fa_number"}
              validation={{ required: false }}
              label={"Nro de calle"}
              placeholder="Nro de calle"
              imgSrc={require("../../../assets/img/png/brand/document.png")}
              errorMessages={[{ type: errorTypes.required, message: 'Ingrese el número de la calle de su domicilio fiscal' }, { type: errorTypes.min, message: 'Número inválido' }]}
              register={register}
              errors={errors}
            />
          </div>
          <div className="col-sm-6">
            <Input
              type={"text"}
              name={"custom:fa_suburb"}
              validation={{ required: false }}
              label={"Colonia"}
              placeholder="Colonia"
              imgSrc={require("../../../assets/img/png/brand/document.png")}
              errorMessages={[{ type: errorTypes.required, message: 'Ingrese la colonia de su domicilio fiscal' }]}
              register={register}
              errors={errors}
            />
          </div>
          <div className="mg-t-10 mg-sm-t-0 col-sm-6">
            <Input
              type={"text"}
              name={"custom:fa_zip_code"}
              validation={{ required: false }}
              label={"Código postal"}
              placeholder="Código postal"
              imgSrc={require("../../../assets/img/png/brand/zip-code.png")}
              errorMessages={[{ type: errorTypes.required, message: 'Ingrese la colonia de su domicilio fiscal' }]}
              register={register}
              errors={errors}
            />
          </div>
          <div className="col-sm-6">
            <Input
              type={"text"}
              name={"custom:fa_city"}
              validation={{ required: false }}
              label={"Ciudad"}
              placeholder="Ciudad"
              imgSrc={require("../../../assets/img/png/brand/document.png")}
              errorMessages={[{ type: errorTypes.required, message: 'Ingrese la ciudad de su domicilio fiscal' }]}
              register={register}
              errors={errors}
            />
          </div>
          <div className="mg-t-10 mg-sm-t-0 col-sm-6">
            <Input
              type={"text"}
              name={"custom:fa_locality"}
              validation={{ required: false }}
              label={"Municipio"}
              placeholder="Municipio"
              imgSrc={require("../../../assets/img/png/brand/document.png")}
              errorMessages={[{ type: errorTypes.required, message: 'Ingrese el municipio de su domicilio fiscal' }]}
              register={register}
              errors={errors}
            />
          </div>
          <div className="mg-t-10 mg-sm-t-0 col-sm-6">
            <Input
              type={"text"}
              name={"custom:fa_state"}
              validation={{ required: false }}
              label={"Estado"}
              placeholder="Estado"
              imgSrc={require("../../../assets/img/png/brand/document.png")}
              errorMessages={[{ type: errorTypes.required, message: 'Ingrese el estado de su domicilio fiscal' }]}
              register={register}
              errors={errors}
            />

          </div>
          <div className="mg-t-10 mg-sm-t-0 col-sm-6">
            <Input
              type={"text"} name={"custom:rfc"}
              validation={{ required: false }}
              label={"R.F.C."}
              placeholder="R.F.C."
              imgSrc={require("../../../assets/img/png/brand/document.png")}
              errorMessages={[{ type: errorTypes.required, message: 'Ingrese los datos de facturación' }]}
              register={register}
              errors={errors}
            />
          </div>
          <div className="mg-t-10 mg-sm-t-0 col-sm-6">
            <Input
              type={"text"} name={"custom:tax_regime"}
              validation={{ required: false }}
              label={"Regimen fiscal"}
              placeholder="Regimen fiscal"
              imgSrc={require("../../../assets/img/png/brand/document.png")}
              errorMessages={[{ type: errorTypes.required, message: 'Ingrese los datos de facturación' }]}
              register={register}
              errors={errors}
            />
            <Input
              type={"text"} name={"custom:cfdi"}
              validation={{ required: false }}
              label={"Uso de CFDI"}
              placeholder="Uso de CFDI"
              imgSrc={require("../../../assets/img/png/brand/document.png")}
              errorMessages={[{ type: errorTypes.required, message: 'Ingrese los datos de facturación' }]}
              register={register}
              errors={errors}
            />
          </div>
        </div>
      </div>

      <button type="submit" className="btn btn-primary btn-block btn">Regístrate</button>

      <div className="main-signin-footer text-center mt-3">
        <p>¿Ya tienes una cuenta? <Link to={`${process.env.PUBLIC_URL}/auth/login`}>Inicia sesión</Link></p>
      </div>
    </form>
  );
};

export default RegisterForm;
