import React, { Fragment, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppThunkDispatch, useAppSelector } from '../../../../app/hooks/redux';
import { fetchQuotations, fetchQuotation } from '../../../../app/store/slice/quotations/asyncTasks';
import { cleanQuotation, setUseBalance, addExtra, removeExtra, removeAllUnusedExtras, selectQuotations } from '../../../../app/store/slice/quotations';
import { selectUsers } from '../../../../app/store/slice/user';

import Quotations from '../../../../components/Orders/OrderQuotations';
import Placeholder from '../../../../components/Placeholder';
import { selectOrders } from '../../../../app/store/slice/orders';

function OrdersQuotation (props: any) {

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const dispatchThunk = useAppThunkDispatch();
    const { order } = useAppSelector(selectOrders);
    const { quotations, useBalance, status } = useAppSelector(selectQuotations);
    const { user } = useAppSelector(selectUsers);

    useEffect(function () {
        dispatch(cleanQuotation());
        dispatchThunk(fetchQuotations(params.id));
    }, [dispatch, dispatchThunk, params]);

    function handleCheckbox (values: any) {
        dispatch(setUseBalance(values));
    }

    function addExtraQuotation(extra: any) {
        dispatch(addExtra(extra))
    }

    function removeExtraQuotation(extra: any) {
        dispatch(removeExtra(extra))
    }

    async function onSubmit (ids: any) {
      const { orderId, quotationId } = ids;
      await dispatchThunk(fetchQuotation({ id: orderId, quotationId: quotationId }));
      dispatch(removeAllUnusedExtras({ quotationId: quotationId }));
      navigate(`/dashboard/orders/${orderId}/quotations/${quotationId}/payment`);
    }

    return (
        <Fragment>
            {
                quotations.length === 0 || status === "idle" ?
                    <Placeholder />
                :
                    <Quotations
                        quotations={quotations}
                        user={user}
                        order={order}
                        useBalance={useBalance}
                        onSubmit={onSubmit}
                        handleCheckbox={handleCheckbox}
                        addExtraQuotation={addExtraQuotation}
                        removeExtraQuotation={removeExtraQuotation}
                    />  
            }  
        </Fragment>
    )
};

export default OrdersQuotation;
