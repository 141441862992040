import React from 'react';
import { useForm } from 'react-hook-form';

import Input from '../../Form/Input';
import Select from '../../Form/Select';

import { requireField, errorTypes } from '../../../utils/formValidations';

function QuickOrdersForm(props: any) {

  const { onSubmit, order } = props;
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: order
  });


  return (
    <div className="row">
      <div className="mx-auto d-block col-xl-6 col-lg-12 col-md-10">
        <div className="card pd-20 pd-md-40 card-body">
          <h5 className="card-title mg-b-20 fs-6">Crea tu cotización</h5>
          <form className="form mx-auto d-block col col-xl-12 col-lg-10 col-md-12" onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
              <div className="col-12 col-sm-12 col-md-6 col-xl-12">
                <div className="form-group">
                  <Select
                    className="form-control"
                    name={"cut_type"}
                    options={["Láser metales", "Láser plásticos", "Router CNC", "Grabado"]}
                    label={"Seleccione tipo de corte"}
                    validation={{ required: requireField }}
                    errorMessages={[{ type: errorTypes.required, message: 'Seleccione el tipo de corte' }]}
                    register={register}
                    errors={errors}
                    disabled={""}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-xl-12">
                <div className="form-group">
                  <Select
                    name={"material_type"}
                    options={["Acero", "Aluminio", "Acero al Carbon", "Acero Inoxidable", "Acrilico", "PVC espumado", "Madera"]}
                    label={"Seleccione el material"}
                    validation={{ required: requireField }}
                    errorMessages={[{ type: errorTypes.required, message: 'Seleccione el material' }]}
                    register={register}
                    errors={errors}
                    disabled={""}
                  />
                </div>
              </div>
            </div>
            <Input
              type={"float"}
              name={"thickness"}
              validation={{ required: requireField }}
              label={"Espesor"}
              placeholder="Pulgadas"
              errorMessages={[{ type: errorTypes.required, message: 'Ingrese el espesor' }]}
              register={register}
              errors={errors}
              disabled={""}
            />
            <Select
              name={"thickness_unit"}
              options={["Metros", "Pulgadas", "Pies", "Centímetros", "Milímetros"]}
              label={"Unidad de medida (Espesor)"}
              validation={{ required: requireField }}
              errorMessages={[{ type: errorTypes.required, message: 'Seleccione la unidad de medida' }]}
              register={register}
              errors={errors}
              disabled={""}
            />
            <div className="form-group">
              <div className="row-sm row">
                <div className="col-sm-6">
                  <Input
                    type={"float"}
                    name={"width"}
                    validation={{ required: requireField }}
                    label={"Ancho"}
                    placeholder="inch"
                    errorMessages={[{ type: errorTypes.required, message: 'Ingrese el ancho (inch)' }]}
                    register={register}
                    errors={errors}
                    disabled={""}
                  />
                </div>
                <div className="mg-t-10 mg-sm-t-0 col-sm-6">
                  <Input
                    type={"float"}
                    name={"length"}
                    validation={{ required: requireField }}
                    label={"Largo"}
                    placeholder="inch"
                    errorMessages={[{ type: errorTypes.required, message: 'Ingrese el largo (inch)' }]}
                    register={register}
                    errors={errors}
                    disabled={""}
                  />
                </div>
              </div>
            </div>
            <Select
              name={"width_length_unit"}
              options={["Metros", "Pulgadas", "Pies", "Centímetros", "Milímetros"]}
              label={"Unidad de medida (Ancho y Largo)"}
              validation={{ required: requireField }}
              errorMessages={[{ type: errorTypes.required, message: 'Seleccione la unidad de medida' }]}
              register={register}
              errors={errors}
              disabled={""}
            />
            <div className="form-group">
              <Input
                type={"text"}
                name={"order_file_path"}
                validation={{ required: requireField }}
                label={"Archivo subido con anterioridad"}
                placeholder="Archivo"
                errorMessages={[{ type: errorTypes.required, message: 'Se necesita Link de Archivo' }]}
                register={register}
                errors={errors}
                disabled={"disabled"}
              />
              <div>
                <Input
                  type={"textarea"}
                  name={"notes"}
                  validation={{ required: false }}
                  label={"Notas (Opcional)"}
                  placeholder="Escribe tus notas aqui"
                  errorMessages={[{ type: errorTypes.required, message: 'Ingrese el largo (inch)' }]}
                  register={register}
                  errors={errors}
                  disabled={""}
                />
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-primary btn-block btn">Crear cotización</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default QuickOrdersForm;
