import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Input from '../../Form/Input';

import { requireField, auth, errorTypes } from '../../../utils/formValidations';

function ConfirmationForm (props: any) {
    const { onSubmit, onResendConfirm } = props;
    const { register, handleSubmit, formState: { errors } } = useForm();

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Input 
                type={"number"} 
                name={"confirmationCode"} 
                validation={{ required: requireField, pattern: auth.confirmationCodeFormat }} 
                label={"Código de confirmación"} 
                errorMessages={[{ type: errorTypes.required, message: 'Ingrese el código de confirmación' }, { type: errorTypes.pattern, message: 'Código de confirmación inválido' }]} 
                register={register} 
                errors={errors} 
            />
            <button type="submit" className="btn btn-primary btn-block btn">Confirmar registro</button>
            {/* Special links */}
            <div className="mt-4 d-flex text-center justify-content-center mb-2">
            </div>
            <div className="main-signin-footer text-center mt-3">
                <p>¿No recibiste el código de confirmación o expiró?<Link to="#" onClick={onResendConfirm} className="mb-3">Reénviar código</Link></p>
            </div>
        </form>
    );
};

export default ConfirmationForm;
