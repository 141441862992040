
import Metal from '../../../assets/img/png/brand/circle-metal.png';
import Plastics from '../../../assets/img/png/brand/circle-plastic.png';
import Tube from '../../../assets/img/png/brand/circle-tubo.png';
import CNC from '../../../assets/img/png/brand/circle-cnc.png';

const map = new Map()
map.set("metal", Metal);
map.set("plastic", Plastics);
map.set("tube", Tube);
map.set("cnc", CNC);

const textMap = new Map()
textMap.set("metal", "Láser Metal");
textMap.set("plastic", "Láser Plásticos");
textMap.set("tube", "Láser Tubo");
textMap.set("cnc", "Router CNC");



export { map, textMap };
