import React from 'react';
import CardItem from './CardItem';
import { debug } from 'console';

function FilesSection(props: any) {

    const { handleDownload, order, delivery_image_urls, reception_image_urls } = props;

    function renderImages(image_urls: string[], file_paths: string[], type: string) {
        let cardItems;
        
        if (image_urls && file_paths) {
            cardItems = image_urls.map((image_url, index) => {
                return <CardItem key={file_paths[index]}
                    image_url={image_url}
                />
            });
        }

        return cardItems
    }

    return (

        <div id="accordion">
            <div className="card">
                <div className="card-header" id="headingOne">
                    <h3 className="mb-0">
                        <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            DFX
                        </button>
                    </h3>
                </div>
                <div id="collapseOne" className="border collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                    <div className="card-body">
                        <button
                            type="button"
                            className="btn ripple btn-success mb-1 me-2"
                            onClick={function () { handleDownload(order.order_file_path, 'singleFile') }}
                        >
                            <i className="fe fe-download me-1"></i> Descargar DFX
                        </button>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header" id="headingTwo">
                    <h3 className="mb-0">
                        <p className="text-center" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Archivos de evidencia recepción de material
                        </p>
                    </h3>
                </div>
                <div id="collapseTwo" className="border collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                    <div className="card-body">
                        <div className="row row-sm" data-bs-spy="scroll" data-bs-offset="0">
                            {
                                renderImages(reception_image_urls, order.reception_file_paths, 'reception_file_paths')
                            }
                        </div>
                        {
                            order.reception_observations ?
                                <div>
                                    <h5 className="">Observaciones de recepción:</h5>
                                    <p className="">{order.reception_observations}</p>
                                </div>
                                : 
                                <></>
                        }
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header" id="headingThree">
                    <h3 className="mb-0">
                        <p className="text-center" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Archivo de evidencia de salida
                        </p>
                    </h3>
                </div>
                <div id="collapseThree" className="border collapse show" aria-labelledby="headingThree" data-parent="#accordion">
                    <div className="card-body">
                        <div className="row row-sm" data-bs-spy="scroll" data-bs-offset="0">
                            {
                                renderImages(delivery_image_urls, order.delivery_file_paths, 'delivery_file_paths')
                            }
                        </div>
                        {
                            order.delivery_observations ?
                                <div>
                                    <h5 className="">Observaciones de entrega:</h5>
                                    <p className="">{order.delivery_observations}</p>
                                </div>
                                : 
                                <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilesSection;
