import { CognitoUserPool, CognitoUser, CognitoUserAttribute, AuthenticationDetails } from 'amazon-cognito-identity-js';
import cognito from '../config/cognito';

// Create UserPool object to authenticate the AWS process
function createUserPool () : CognitoUserPool{

  let userPool: CognitoUserPool = new CognitoUserPool({
    UserPoolId: cognito.userPoolId,
    ClientId: cognito.clientId,
  });

  return userPool;
};

// - customer_custom_id =  <Email><Date><Epoch>  

function getCustomerCustomId(email: string) {
  const letters = email.substring(0,2).toUpperCase();
  
  const date = new Date();
  const formatter = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: '2-digit' });
  const formattedDate = formatter.format(date).replaceAll('/','');

  const now = Date.now().toString();
  const lastDigits = now.slice(-5);

  const customCustomerId = ''.concat(letters, formattedDate, lastDigits);

  return customCustomerId;  
}

function createAttributeList (values: any) {

  let attributeList: Array<CognitoUserAttribute> = [];
  let objectKeys = Object.keys(values);

  objectKeys.forEach(function (key) {

    if (key === "password") {
      return;
    }
    
    if(key === 'confirm_password'){
      return;
    }

    let data = { Name: key, Value: values[key] };
    let attribute: CognitoUserAttribute = new CognitoUserAttribute(data);

    attributeList.push(attribute);
  });

  return attributeList;
 };

export async function cognitoSignUp (values: any) {

  let userPool: CognitoUserPool = createUserPool();
  values['custom:customer_custom_id'] = getCustomerCustomId(values.email)

  let attributeList: Array<CognitoUserAttribute> = createAttributeList(values);
  
  return new Promise(function (resolve, reject) {
    userPool.signUp(values.email, values.password, attributeList, [], function (err, result) {

      if (err) {
        alert(err.message || JSON.stringify(err));
        reject(err);
      }

      const user = {
        email: result?.user.getUsername(),
        userConfirmed: result?.userConfirmed,
        isSigned: true,
      };

      resolve(user);
    });
  });
};

export async function cognitoConfirmRegistration (values: any) {

  let userPool: CognitoUserPool = createUserPool();
  let userData = {
    Username: values.email,
    Pool: userPool
  };

  let cognitoUser: CognitoUser = new CognitoUser(userData);

  return new Promise (function (resolve, reject) {
    cognitoUser.confirmRegistration(values.confirmationCode, true, function(err, result) {

      if (err) {
        alert(err.message || JSON.stringify(err));
        reject(err);
      }

      const user = {
        email: values.email,
        userConfirmed: true,
        isSigned: false,
      };

      resolve(user);
    });
  });
};

export async function cognitoResendConfirmation (email: any) {

  let userPool: CognitoUserPool = createUserPool();
  let userData = {
    Username: email,
    Pool: userPool
  };

  let cognitoUser: CognitoUser = new CognitoUser(userData);

  return new Promise (function (resolve, reject) {
    cognitoUser.resendConfirmationCode(function(err, result) {

      if (err) {
        alert(err.message || JSON.stringify(err));
        reject(err);
      }

      resolve(result);
    });
  });
};

export async function cognitoSignIn (values: any) {

  let authenticationData = {
    Username: values.email,
    Password: values.password,
  };

  let authenticationDetails: AuthenticationDetails = new AuthenticationDetails(authenticationData);
  let userPool: CognitoUserPool = createUserPool();

  let userData = {
    Username: values.email,
    Pool: userPool
  };

  let cognitoUser: CognitoUser = new CognitoUser(userData);

  return new Promise(function (resolve, reject) {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        
        const user = {
          id: result.getIdToken().decodePayload()["cognito:username"],
          accessToken: result.getIdToken().getJwtToken(),
          email: values.email,
          userConfirmed: true,
          isSigned: true,
        };

        resolve(user);
      },
  
      newPasswordRequired: function(userAttributes, requiredAttributes) {
        // User was signed up by an admin and must provide new
        // password and required attributes, if any, to complete
        // authentication.
  
        // the api doesn't accept those field back
        delete userAttributes.email_verified;
        delete userAttributes.email;
  
        cognitoUser.completeNewPasswordChallenge(authenticationData.Password, userAttributes, this);
      },
    
      onFailure: function(err) {

        alert(err.message || JSON.stringify(err));

        if (err.message === "User is not confirmed.") {

          const user = {
            email: values.email,
            userConfirmed: false,
            isSigned: true,
          };

          resolve(user);
        }

        reject(err);
      },
    });
  });
};

export async function cognitoChangePassword (values: any) {
  
  let userPool: CognitoUserPool = createUserPool();
  let currentUser = userPool.getCurrentUser();

  return new Promise (function (resolve, reject) {
    
    if (currentUser) {
      currentUser.getSession(function(err: any, session: any) {
        
        if (err) {
          reject({ message: "Error getting session:'" });
        
        } else if (!session.isValid()) {
          reject({ message: "User is not fully authenticated" });
        
        } else {
          const accessToken = session.getAccessToken().getJwtToken();
          const idToken = session.getIdToken().getJwtToken();
    
          currentUser?.changePassword(values.password, values.newPassword, function(err, result) {
            
            if (err) {
              reject({ message: "Error changing password" });
            
            } else { 
              resolve({ message: "Password successfully changed" });

            }
          }, { accessToken, idToken });
        }
      });

    } else {
      reject({ message: "No user signed in" });
    }
  });
};

export async function cognitoSendForgotPasswordCode (email: any) {
  
  let userPool: CognitoUserPool = createUserPool();
  let userData = {
    Username: email,
    Pool: userPool
  };

  let cognitoUser: CognitoUser = new CognitoUser(userData);

  return new Promise (function (resolve, reject) {
    cognitoUser.forgotPassword({
      onSuccess: function(data) {
        // successfully initiated reset password request
        const user = {
          email: email,
          userConfirmed: false,
          isSigned: false,
        };
        resolve(user);
      },
      onFailure: function(err) {
        alert(err.message || JSON.stringify(err));
        reject(err);
      },
    });
  });
};

export async function cognitoForgotPassword (values: any) {
  
  let userPool: CognitoUserPool = createUserPool();
  let userData = {
    Username: values.email,
    Pool: userPool
  };

  let cognitoUser: CognitoUser = new CognitoUser(userData);

  return new Promise (function (resolve, reject) {
    cognitoUser.confirmPassword(values.verificationCode, values.newPassword, {
      onSuccess() {
        resolve({ message: 'Password confirmed!' });
      },
      onFailure(err) {
        alert(err.message || JSON.stringify(err));
        reject(err);
      },
    });
  });
};

export async function cognitoSignOut () {
  
  let userPool: CognitoUserPool = createUserPool();
  let cognitoUser = userPool.getCurrentUser();

  return new Promise (function (resolve, reject) {
    cognitoUser?.signOut();
    resolve({ message: "Succesfully sign out" });
  });
};
