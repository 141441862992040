import React, { useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import HybridModal from '../../Modal/HybridModal';

function OrderQuotationItem (props: any) {

    const { order, quotation, user, quotationType, useBalance, onSubmit, handleCheckbox, addExtraQuotation, removeExtraQuotation } = props;
    const [isUseBalance, setIsUseBalance] = useState(false);
    const [ showModal, setShowModal ] = useState(false);

    
    function handleCheckboxExtraQuotation (e: React.ChangeEvent<HTMLInputElement>) {
      let extra = quotation.extras.find((extra: any) => extra.description === e.target.name);
      extra = { ...extra, quotation_id: e.target.id};
      if (e.target.checked) {
        addExtraQuotation(extra)
      } else {
        removeExtraQuotation(extra)
      }
    }
    
    function onDismiss () {
      setShowModal(false);
    };

    function modalNoAction (){
      setIsUseBalance(false);
      setShowModal(false);
      handleCheckbox({ quotationId: quotation.quotation_id, isUse: false });
      onSubmit({ orderId: quotation.order_id, quotationId: quotation.quotation_id });
    };

    function modalAction () {
      setIsUseBalance(true);
      setShowModal(false);
      handleCheckbox({ quotationId: quotation.quotation_id, isUse: true });
      onSubmit({ orderId: quotation.order_id, quotationId: quotation.quotation_id });
    };

    const renderExtraOptions = (extras: any) => {
      return extras.map((extra: any, index: number) => {
        const address = `${user.fa_street} ${user.fa_number} ${user.fa_suburb} ${user.fa_zip_code} ${user.fa_city} ${user.fa_state}`;
        return (
          <div key={index} className="card border rounded extra-container border-gray">
              <div className="card-body">
                <div className="checkbox">
                    <div className="d-flex">
                    <input
                        id={quotation.quotation_id}
                        className="form-label"
                        type="checkbox"
                        name={extra.description}
                        onChange={handleCheckboxExtraQuotation}
                    />
                    <label
                      className="mx-2 form-label"
                      htmlFor={extra.description}
                    >
                      {extra.description === "extra_material_reception" ? "Recolección de material a tu domicilio ZMG 15km" : "Entrega del corte a tu domicilio ZMG 15km"}
                    </label>
                    </div>
                </div>
                <dl className="dlist-align mt-3">
                  <dt className="">Precio: </dt>
                  <dd className="text-end ms-auto">${extra.price}</dd>
                </dl>
                <p><span className="font-weight-bold">Dirección: </span>{address}</p>
              </div>
          </div>
        );
      });
    }

    return (
      <React.Fragment>
        <Col xl={4} md={6}>
            <Card className="mg-b-20">
                <Card.Body className=" p-0">
                <div className={`todo-widget-header d-flex flex-column pb-2 pd-20 border-bottom ${quotationType.backgroundColorClassName}`}>
                    <h3>{quotationType.text}</h3>
                    <p>{quotationType.phrase}</p>
                </div>
                <div className="p-4">
                    <h4>{order.cut_type}</h4>
                    
                    <h5 className="tx-14 mb-0 mg-t-5 text-capitalize">
                    {quotationType.conditions}
                    </h5>
                </div>
                <div className="p-4 border-top">
                    <h4>Material de entrega</h4>
                    <h5 className="tx-17 mb-0 mg-t-5 ">
                      {quotation.due_date.slice(0,10)}
                    </h5>
                </div>
                {
                  !quotation.extras ?
                    null
                  :
                    <div className="border m-2">
                      <h3>Servicios Opcionales</h3>
                      {renderExtraOptions(quotation.extras)}
                    </div> 
                }
                </Card.Body>
                <Card.Footer>
                <dl className="dlist-align">
                    <h3>Monto a pagar:</h3>
                    <dd className="text-end  ms-auto tx-40">
                      MXN
                    <strong> ${quotation.price}</strong>
                    </dd>
                </dl>
                {
                    user.balance === undefined || user.balance <= 0 ?
                     null
                    :
                    <div className="checkbox">
                        <div className="d-flex">
                        <input
                            id="isUseBalance"
                            className="form-label"
                            type="checkbox"
                            name="isUseBalance"
                            onChange={function (e) {
                                setIsUseBalance(e.target.checked);
                                handleCheckbox({ quotationId: quotation.quotation_id, isUse: e.target.checked });
                              }}
                              disabled={quotation.quotation_id !== useBalance?.quotationId && useBalance?.isUse ? true : false}
                              checked={isUseBalance}
                        />
                        <label className="mt-5 mb-5  mx-2 form-label" htmlFor={"isUseBalance"}>{"¿Quieres utilizar tu saldo para pagar la cotización"}</label>
                        </div>
                    </div>
                }
                <button
                    className="btn btn-lg btn-primary  btn-block btn btn-primary"
                    onClick={function () {
                        !isUseBalance && user.balance > 0 ? 
                        setShowModal(true)
                        : 
                        onSubmit({ orderId: quotation.order_id, quotationId: quotation.quotation_id });
                    }}
                >
                    Seleccionar
                </button>
                </Card.Footer>
            </Card>
          </Col>
          <HybridModal
            showModal={showModal}
            title={'¿Quisieras usar tu saldo disponible?'}
            firstButton={'Usar Saldo'}
            secondButton={'No Usar Saldo'}
            onDismiss={onDismiss}
            modalAction={modalAction}
            modalNoAction={modalNoAction}
          />
      </React.Fragment>
    );
};

export default OrderQuotationItem;
