import { createSlice } from '@reduxjs/toolkit';
import { RootState} from '../..';
import { AuthState } from '../../../../models/auth';

import { signIn, signUp, confirmRegistration, resendConfirmation,
  sendForgotPasswordCode, changePassword, forgotPassword, signOut
} from './asyncTasks';

const initialState: AuthState = {
  user: {
    userConfirmed: false,
    isSigned: false,
  },
  status: 'idle',
  message: ''
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    cleanState: function () {},
    changeStatusToIdle: function (state) {
      state.status = "idle";
      state.message = "";
    }
  },
  extraReducers: function (builder) {
    builder
      .addCase(signIn.pending, function (state, action) {
        state.status = "loading";
      })
      .addCase(signIn.fulfilled, function (state, action) {
        state.user = action.payload;
        state.status = "fulfilled";
      })
      .addCase(signIn.rejected, function (state, action) {
        state.status = "failed"
        state.message = action.payload;
      })

      .addCase(signUp.pending, function (state, action) {
        state.status = "loading";
      })
      .addCase(signUp.fulfilled, function (state, action) {
        state.user = action.payload;
        state.status = "fulfilled";
      })
      .addCase(signUp.rejected, function (state, action) {
        state.status = "failed"
        state.message = action.payload;
      })

      .addCase(confirmRegistration.pending, function (state, action) {
        state.status = "loading";
      })
      .addCase(confirmRegistration.fulfilled, function (state, action) {
        state.user = action.payload;
        state.status = "fulfilled";
      })
      .addCase(confirmRegistration.rejected, function (state, action) {
        state.status = "failed"
        state.message = action.payload;
      })

      .addCase(resendConfirmation.pending, function (state, action) {
        state.status = "loading";
      })
      .addCase(resendConfirmation.fulfilled, function (state, action) {
        state.message = action.payload;
        state.status = "fulfilled";
      })
      .addCase(resendConfirmation.rejected, function (state, action) {
        state.status = "failed"
        state.message = action.payload;
      })

      .addCase(changePassword.pending, function (state, action) {
        state.status = "loading";
      })
      .addCase(changePassword.fulfilled, function (state, action) {
        state.user = action.payload;
        state.status = "fulfilled";
      })
      .addCase(changePassword.rejected, function (state, action) {
        state.status = "failed"
        state.message = action.payload;
      })

      .addCase(sendForgotPasswordCode.pending, function (state, action) {
        state.status = "loading";
      })
      .addCase(sendForgotPasswordCode.fulfilled, function (state, action) {
        state.user = action.payload;
        state.status = "fulfilled";
      })
      .addCase(sendForgotPasswordCode.rejected, function (state, action) {
        state.status = "failed"
        state.message = action.payload;
      })

      .addCase(forgotPassword.pending, function (state, action) {
        state.status = "loading";
      })
      .addCase(forgotPassword.fulfilled, function (state, action) {
        state.message = action.payload;
        state.status = "fulfilled";
      })
      .addCase(forgotPassword.rejected, function (state, action) {
        state.status = "failed"
        state.message = action.payload;
      })

      .addCase(signOut.pending, function (state, action) {
        state.status = "loading";
      })
      .addCase(signOut.fulfilled, function (state, action) {
        state.message = action.payload;
        state.status = "fulfilled";
      })
      .addCase(signOut.rejected, function (state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
  },
});

export const { cleanState, changeStatusToIdle } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export function selectAuth (state: RootState) { return state.auth };

export default authSlice.reducer;
