import React from 'react';
import { Link } from 'react-router-dom';

export const ORDERS_MODEL: any = [
  {
    Header: "ID",
    accessor: "order_custom_id",
    className: "text-center",
  },
  {
    Header: "Fecha de creación",
    accessor: "creation_date",
    className: "text-center ",
  },
  {
    Header: "Estatus",
    accessor: "status",
    Cell: function (cell: any) { return <span className="badge badge-info-transparent">{cell.row.original.status}</span> },
    className: "text-center ",
  },
  {
    Header: "Tipo De Corte",
    accessor: "cut_type",
    className: "text-center ",
  },
  {
    Header: "Material",
    accessor: "material_type",
    className: "text-center ",
  },
  {
    Header: "Notas",
    accessor: "notes",
    className: "text-center ",
  },
  {
    Header: "Detalle",
    accessor: "detail",
    Cell: function (cell: any) { return <Link to={`/dashboard/orders/${cell.row.original.order_id}/detail`} className="btn btn-primary btn-sm rounded-11 me-2 p-2">Ver más...</Link> },
    className: "text-center ",
  }
];

export const ORDERS_HISTORY_MODEL: any = [
  {
    Header: "ID Cotización",
    accessor: "order_id",
    className: "text-center ",
  },
  {
    Header: "Fecha de creación",
    accessor: "creation_date",
    className: "text-center ",
  },
  {
    Header: "Estatus",
    accessor: "status",
    Cell: function (cell: any) { return <span className="badge badge-info-transparent">{cell.row.original.status}</span> },
    className: "text-center ",
  },
  {
    Header: "Tipo De Corte",
    accessor: "cut_type",
    className: "text-center ",
  },
  {
    Header: "Recrear pedido",
    accessor: "quick_creation",
    Cell: function (cell: any) { return <Link to={`/dashboard/orders/${cell.row.original.order_id}/quick-creation`} className="btn btn-primary btn-sm rounded-11 me-2 p-2">Recrear</Link> },
    className: "text-center ",
  }
];

export const BANK_ACCOUNTS_MODEL: any = [
  {
      Header: "Banco",
      accessor: "bank",
      className: "text-center ",
    },
    {
      Header: "Cuenta CLABE",
      accessor: "account",
      className: "text-center ",
    },  
];


export const GlobalFilter = ({ filter, setFilter }: any) => {
  return (
    <span className="d-flex ms-2">
      <input
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        className="form-control mb-4"
        placeholder="Buscar..."
      />
    </span>
  );
};
