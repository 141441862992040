import React from 'react';

function Input (props: any) {

    const { type, name, validation, label, placeholder, imgSrc, errorMessages, register, disabled, errors, step} = props;

    return (
        <div className="form-group">
            <label className="form-label fs-6">{label}</label>
            <div className="d-flex">
                {
                    !imgSrc ?
                        null
                    :
                    <img
                        src={imgSrc}
                        className="ht-30 m-1"
                        alt="input-img"
                    />
                }
                <input
                    min={validation.min}
                    placeholder={placeholder}
                    className="form-control"
                    type={type}
                    step={step}
                    {...register(name, validation)}
                    disabled={disabled}
                />
            </div>
            {errorMessages.map(function(errorMessage: any){
                return errors[name] && errors[name].type === errorMessage.type && <p key={errorMessage} className="text text-danger">{errorMessage.message}</p>
            })}
        </div>
    );
};

export default Input;
