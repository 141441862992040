import React from 'react';

function BalanceDetail (props: any) {
    
  const { amount } = props;

    return (
      <div className="card cart-details p-5">
        <div className="card-body">
          <h5 className="mb-3 font-weight-bold tx-14">Detalle del saldo</h5>
        </div>
        <div className="card-footer">
          <div className="column">
          <dl className="dlist-align">
            <h3>Saldo para agregar:</h3>
            <dd className="text-end  ms-auto tx-20">
              <strong>${amount}</strong>
            </dd>
          </dl>
          </div>
        </div>
      </div>
    );
};

export default BalanceDetail;
