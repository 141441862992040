import React, { useEffect }from 'react';
import { useParams } from 'react-router-dom';

import { useAppThunkDispatch, useAppSelector } from '../../../../app/hooks/redux';
import { selectOrders } from '../../../../app/store/slice/orders';
import { fetchOrder, updateOrder, getFiles } from '../../../../app/store/slice/orders/asyncTasks';
import { fetchQuotation } from '../../../../app/store/slice/quotations/asyncTasks';

import OrderDetail from '../../../../components/Orders/OrderDetail';
import Placeholder from '../../../../components/Placeholder';
import { cleanQuotation, selectQuotations } from '../../../../app/store/slice/quotations';

function OrdersDetail (props: any) {
    const params = useParams();
    const dispatch = useAppThunkDispatch();
    const { order, status, reception_image_urls, delivery_image_urls } = useAppSelector(selectOrders);
    const { quotation } = useAppSelector(selectQuotations)


    useEffect(function () {
        async function handleOrderFetchingDetail() {
            dispatch(cleanQuotation());
            
            const orderHelper = await dispatch(fetchOrder(params.id)).unwrap();
            const order = orderHelper.data;

            if(order.payed_quotation_id) {
                dispatch(fetchQuotation({id: order.order_id, quotationId: order.payed_quotation_id}));
            }

            await dispatch(getFiles({ client_id: order.client_id, paths: order.reception_file_paths, downloadType: 'reception_file_paths' }));
            await dispatch(getFiles({ client_id: order.client_id, paths: order.delivery_file_paths, downloadType: 'delivery_file_paths' }));

        }
        handleOrderFetchingDetail()

    }, [dispatch, params]);

    // async function handleDownload(filePath: string, downloadType: string) {

    //     const { presignedUrl } = await dispatch(getFiles({ client_id: order.client_id, path: filePath, downloadType: downloadType })).unwrap();

    //     const link = document.createElement('a');
    //     link.href = presignedUrl;
    //     link.download = filePath;
    //     link.click();
    // };

    function handleChangeStatus (status: string) {
        dispatch(updateOrder({
            id: params.id,
            order: {
                status: status,
            },
        }));
    };

    return (
        <div>
            {
                !order || status === "loading" ?
                    <Placeholder />
                :
                    <OrderDetail order={order}
                    quotation={quotation}
                    handleChangeStatus={handleChangeStatus}
                    // handleDownload={handleDownload}
                    reception_image_urls={reception_image_urls}
                    delivery_image_urls={delivery_image_urls}
                    /> 
            }
                 
        </div>
    )
}
export default OrdersDetail;

