import React, { Fragment, useEffect } from 'react';

import { useAppThunkDispatch, useAppSelector } from '../../../app/hooks/redux';
import { selectOrders } from '../../../app/store/slice/orders';
import { fetchOrders } from '../../../app/store/slice/orders/asyncTasks';

import OrdersTable from '../../../components/Table/Orders';
import EmptyInfo from '../../../components/EmptyInfo';
import Placeholder from '../../../components/Placeholder';

import { ORDERS_MODEL } from '../../../components/Table/utils/models';

import img from '../../../assets/img/png/grafico-de-barras.png'

function Orders () {

    const dispatch = useAppThunkDispatch();
    const { orders } = useAppSelector(selectOrders);

    useEffect(function (){
        dispatch(fetchOrders());
    }, [dispatch]);

    return (
        <Fragment>  
            {
                orders.status === "idle" ?
                    <Placeholder />
                : orders.length === 0 ?
                    <EmptyInfo img={img}/>
                    : 
                    <OrdersTable data={orders} columns={ORDERS_MODEL}/>
            }
        </Fragment>
    )
}
export default Orders;
