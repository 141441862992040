import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks/redux';
import { signIn } from '../../app/store/slice/auth/asyncTasks';

import LoginComponent from '../../components/Auth/Login';
import { SignInPayload } from '../../models/auth';

function Login () {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    function onSubmit (formValues: SignInPayload) {
        dispatch(signIn(formValues));
        navigate("/auth/login");
    };
    return (
        <LoginComponent onSubmit={onSubmit} />
    );
};

export default Login;
