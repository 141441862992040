import React, { useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useAppThunkDispatch, useAppSelector } from '../../../../app/hooks/redux';
import { fetchQuotations } from '../../../../app/store/slice/quotations/asyncTasks';
import { selectQuotations } from '../../../../app/store/slice/quotations';

import OrdersCalendar from '../../../../components/Calendar/Orders';
import Placeholder from '../../../../components/Placeholder';


function OrdersCalendarPayment () {

    const params = useParams();
    const dispatch = useAppThunkDispatch();
    const { quotations, status } = useAppSelector(selectQuotations);

    useEffect(function () {
        dispatch(fetchQuotations(params.id));
    }, [dispatch, params]);

    return (
        <Fragment>
            {
                quotations.length === 0 || status === 'idle' ? 
                    <Placeholder />
                 :   
                     <OrdersCalendar quotations={quotations} isFromCalendarContainer={true} height={"800px"}/> 
            }
                 
        </Fragment>
    );
};

export default OrdersCalendarPayment;
