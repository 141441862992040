import React from 'react';
import '../styles.css';

import MetalG from '../../../assets/img/png/brand/green-metal.png';
import PlasticsG from '../../../assets/img/png/brand/green-plastico.png';
import TubeG from '../../../assets/img/png/brand/green-tubo.png';
import CNCG from '../../../assets/img/png/brand/green-cnc.png';

import { map } from './constants';

function ImgSelector (props: any) {

  const { handleOnClick, cutType } = props;
  
  return (
   <div className="d-flex justify-content-evenly align-items-center flex-column">

    <div className="circular-image-container-orders">
      <button className="borderless-button-orders" onClick={function(){ handleOnClick("metal") }}>
        <img src={cutType === "metal"? map.get(cutType) : MetalG}/>
      </button>
    </div>
    <div className="d-flex justify-content-center w-100">
      <p className="card-title-orders">Láser Metal</p>
    </div>
    
    <div className="circular-image-container-orders">
      <button className="borderless-button-orders" onClick={function(){ handleOnClick("plastic") }}>
      <img src={cutType === "plastic"? map.get(cutType) : PlasticsG}/>
      </button>
    </div>
    <div className="d-flex justify-content-center w-100">
      <p className="card-title-orders">Láser Plásticos</p>
    </div>

    <div className="circular-image-container-orders">
      <button className="borderless-button-orders" onClick={function(){ handleOnClick("tube") }}>
      <img src={cutType === "tube"? map.get(cutType) : TubeG}/>
      </button>
    </div>
    <div className="d-flex justify-content-center w-100">
      <p className="card-title-orders">Láser Tubo</p>
    </div>
    
    <div className="circular-image-container-orders">
      <button className="borderless-button-orders"  onClick={function(){ handleOnClick("cnc") }}>
      <img src={cutType === "cnc"? map.get(cutType) : CNCG}/>
      </button>
    </div>
    <div className="d-flex justify-content-center w-100">
      <p className="card-title-orders">Router CNC</p>
    </div>
    
   </div>
  );
};

export default ImgSelector;
