import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppThunkDispatch, useAppSelector } from '../../../../app/hooks/redux';
import { updateQuotation } from '../../../../app/store/slice/quotations/asyncTasks';
import { selectQuotations } from '../../../../app/store/slice/quotations';
import { selectOrders } from '../../../../app/store/slice/orders';
import { setStripeError, cleanPaymentIntent, selectPayments } from '../../../../app/store/slice/payments';
import { fetchClientSecretPaymentIntent } from '../../../../app/store/slice/payments/asyncTasks';
import { updateOrder } from '../../../../app/store/slice/orders/asyncTasks';
import { updateUser } from '../../../../app/store/slice/user/asyncTasks';
import { selectUsers } from '../../../../app/store/slice/user';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { stripeConfig } from '../../../../config/stripe';

import { StripeTypes } from '../../../../models/stripe';

import OrderPaymentComponent from '../../../../components/Orders/OrderPayment';
import Placeholder from '../../../../components/Placeholder';


const stripePublishableKey = loadStripe(stripeConfig.publishableKey);

function OrderPayment () {

    const [amountLeft, setAmountLeft] = useState(0);
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useAppDispatch();
    const dispatchThunk = useAppThunkDispatch();
    const { payment, status, message } = useAppSelector(selectPayments);
    const { quotation, useBalance, extras } = useAppSelector(selectQuotations);
    const { order } = useAppSelector(selectOrders);
    const { user } = useAppSelector(selectUsers);

    const extrasSum = extras.reduce((accumulator: number, extra: any) => accumulator + extra.price, 0);

    useEffect(function () {

            if (useBalance && quotation.quotation_id === useBalance.quotationId && useBalance.isUse) {

                const total = (quotation.price + extrasSum) - user.balance;
                if (total > 0) {

                    dispatchThunk(fetchClientSecretPaymentIntent(total));
                    setAmountLeft(total);
                } else {
                    setAmountLeft(total);
                }
            } else {
                dispatchThunk(fetchClientSecretPaymentIntent(quotation.price + extrasSum));
            };
        return function () {
            dispatch(cleanPaymentIntent());
        };
    }, [dispatchThunk, dispatch, quotation, user, useBalance]);

    const options: StripeTypes = {
        clientSecret: payment?.client_secret,
        appearance: {
        theme: "stripe",
        variables: {
            colorPrimary: '#008b8b'
          },
        },
    };

    function getCorrectExtras(extras: any, quotation: any, user: any) {
        const address = `${user.fa_street} ${user.fa_number} ${user.fa_suburb} ${user.fa_zip_code} ${user.fa_city} ${user.fa_state}`;

        return extras.map((extra: any) => {
            if (extra.quotation_id === quotation.quotation_id) {
                return {
                    description: extra.description,
                    price: extra.price,
                    user_input: address,
                    is_selected: true,
                }
            } else {
                throw new Error("id's are not matching")
            }
        })
    }

    function quotationWithExtras(extras: any, withBalance: boolean, quotation: any, user: any, id: any = undefined) {
        if (withBalance) {
            if (extras.length > 0) {
                return {
                    status: "payed",
                    payment_type: "card_payment",
                    payment_reference: "Pagado en su totalidad utilizando saldo a favor",
                    extras: getCorrectExtras(extras, quotation, user),
                }
            }
            return {
                status: "payed",
                payment_type: "card_payment",
                payment_reference: "Pagado en su totalidad utilizando saldo a favor",
            }
        }

        if (extras.length > 0) {
            return {
                status: "payed",
                payment_type: "card_payment",
                payment_reference: id,
                extras: getCorrectExtras(extras, quotation, user),
            };
        }
        return {
            status: "payed",
            payment_type: "card_payment",
            payment_reference: id,
        };
    }

    async function onSubmit (values: any) {

        try {
            const { stripe, elements } = values;

        // Necesary to execute this Promise in the container.
        // return_url a required option and this option prevent to 
        // redux to finish the Action process
        const { paymentIntent } = await stripe.confirmPayment({
            elements,
            redirect: "if_required",
        });

        const { id } = paymentIntent;

        await dispatchThunk(updateOrder({
            id: quotation.order_id,
            order: {
                payed_quotation_id:  quotation.quotation_id,
                status: "payed",
                payment_type: "card_payment",
                payment_reference: id,
            },
        }));
        await dispatchThunk(updateQuotation({
            id: quotation.order_id,
            quotationId: quotation.quotation_id,
            quotation: quotationWithExtras(extras, false, quotation, user, id),
        }));

        if (useBalance && quotation.quotation_id === useBalance.quotationId && useBalance.isUse) {
            await dispatchThunk(updateUser({ balance: (quotation.price - user.balance) < 0 ? user.balance - quotation.price : 0 }));
        };

        navigate("/dashboard/payments");

        } catch (error: any) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            if (error.type === "card_error" || error.type === "validation_error") {
                dispatch(setStripeError(error.message));
            } else {
                dispatch(setStripeError("An unexpected error occurred."));
            };
        }
    };

    function onSubmitWithBalance () {

        dispatchThunk(updateUser({ balance: user.balance - (quotation.price + extrasSum)}));

        dispatchThunk(updateOrder({
            id: quotation.order_id,
            order: {
                status: "payed",
                payment_type: "card_payment",
                payment_reference: "Pagado en su totalidad utilizando saldo a favor",
            },
        }));
        dispatchThunk(updateQuotation({
            id: quotation.order_id,
            quotationId: quotation.quotation_id,
            quotation: quotationWithExtras(extras, true, quotation, user),
        }));

        navigate("/dashboard/payments");
    };

    function renderCorrectComponent () {
        if (useBalance && useBalance.isUse && amountLeft <= 0) {
            return <OrderPaymentComponent
                        quotation={quotation}
                        amountLeft={amountLeft}
                        useBalance={useBalance}
                        extras={extras}
                        extrasSum={extrasSum}
                        user={user}
                        order={order}
                        onSubmit={onSubmit}
                        onSubmitWithBalance={onSubmitWithBalance}
                    />
        };

        if (payment && quotation && status !== "idle") {
            return <Elements stripe={stripePublishableKey} options={options}>
                        <OrderPaymentComponent
                            quotation={quotation}
                            amountLeft={amountLeft}
                            useBalance={useBalance}
                            extras={extras}
                            extrasSum={extrasSum}
                            user={user}
                            order={order}
                            onSubmit={onSubmit}
                            onSubmitWithBalance={onSubmitWithBalance}
                        />
                        {/* Show any error or success messages */}
                        {message && <div id="payment-message">{message}</div>}
                    </Elements>
        };
        return <Placeholder />
    }

    return renderCorrectComponent();
};

export default OrderPayment;
