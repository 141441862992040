import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks/redux';
import { sendForgotPasswordCode } from '../../app/store/slice/auth/asyncTasks';

import SendForgotPasswordCodeComponent from '../../components/Auth/SendForgotPasswordCode';

function SendForgotPasswordCode () {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    function onSubmit (formValues: any) {
        dispatch(sendForgotPasswordCode(formValues.email));
        navigate("/auth/forgot-password");
    };

    return (
        <SendForgotPasswordCodeComponent onSubmit={onSubmit} />
    );
};

export default SendForgotPasswordCode;
