import { createAppAsyncThunk } from '../../../hooks/redux';
import cutDesignApi from '../../../../apis/cutDesignApi';
import { User } from '../../../../models/auth';
import {cutTypeIdMap} from '../../slice/orders/constants'

import axios from 'axios';

import { signOut } from '../auth/asyncTasks';

import { UNAUTHORIZED_ERROR_TYPE, UNAUTHORIZED_ERROR_MESSAGE } from '../../../../utils/types';

export const fetchOrders = createAppAsyncThunk('orders/fetchOrders', async function (_, { rejectWithValue, getState, dispatch }) {
    try {

        const user: User = getState().auth.user;

        const res = await cutDesignApi.get(`/clients/${user.id}/orders`, {
            headers: {
                Authorization: user.accessToken,
            }
        });
        return res;
    } catch (err: any) {

        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };

            dispatch(signOut());
        };

        return rejectWithValue(err.response.data);
    }
});

export const fetchOrder = createAppAsyncThunk('orders/fetchOrder', async function (id: any, { rejectWithValue, getState, dispatch }) {
    try {

        const user: User = getState().auth.user;


        const res = await cutDesignApi.get(`/clients/${user.id}/orders/${id}`, {
            headers: {
                Authorization: user.accessToken,
            }
        });
        return res;

    } catch (err: any) {
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
            dispatch(signOut());
        };

        return rejectWithValue(err.response.data);
    }
});

// - Orden <CutType><Material><Date><Epoch>
//  CNCMA300424093

function createOrderCustomId(cutType: string, materialType: string){
    let materialLetters = '';
    
    const cutLetters = cutTypeIdMap.get(cutType).substring(0,3).toUpperCase();

    if( materialType.indexOf('Acero') !== -1 && materialType.split(' ').length >= 2) {
        materialLetters = materialType.split(' ')[1].substring(0, 2).toUpperCase();
    } else {
        materialLetters = materialType.substring(0, 2).toUpperCase();
    }
   
    const date = new Date();
    const formatter = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: '2-digit' });
    const formattedDate = formatter.format(date).replaceAll('/','');

    const now = Date.now().toString();
    const lastDigits = now.slice(-4);

    const customOrderId = ''.concat(cutLetters, materialLetters, formattedDate, lastDigits);

    return customOrderId;  
}

async function getPresignedUrl({ user, extension, path, endpointType }: any) {
    try {

        const value = !path ? { file_extension: extension } : { file_path: path }
        const res = await cutDesignApi.post(`/clients/${user.id}/presigned_url/${endpointType}`, value, {
            headers: {
                Authorization: user.accessToken,
            }
        });
        return res;
    } catch (err: any) {
        return err;
    }
};

async function uploadToS3({ presignedResult, file }: any) {
    try {

        const res = await axios.put(presignedResult.data.presigned_url, file);

        return res;
    } catch (err: any) {
        return err;
    }
};


export const createOrder = createAppAsyncThunk('orders/createOrder', async function (values: any, { rejectWithValue, getState, dispatch }) {
    try {
        const user: User = getState().auth.user;
        
        const endpointType = 'put';
        
        values['order_custom_id'] = createOrderCustomId(values.cut_type, values.material_type)
    
        if (!values.order_file_path) {
            const file: File = values.file[0];
            const extension: string = file.name.slice((Math.max(0, file.name.lastIndexOf(".")) || Infinity) + 1);

            const presignedResult = await getPresignedUrl({ user, extension, endpointType });
            values.order_file_path = presignedResult.data.file_path;

            await uploadToS3({ presignedResult, file })
        }

        const res = await cutDesignApi.post(`/clients/${user.id}/orders`, values, {
            headers: {
                Authorization: user.accessToken,
            }
        });

        return res;
    } catch (err: any) {

        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
            dispatch(signOut());
        };

        return rejectWithValue(err.response.data);
    }
});

export const updateOrder = createAppAsyncThunk('orders/updateOrder', async function (values: any, { rejectWithValue, getState, dispatch }) {
    try {

        const user: User = getState().auth.user;
        const { id, order } = values;

        const res = await cutDesignApi.put(`/clients/${user.id}/orders/${id}`, order, {
            headers: {
                Authorization: user.accessToken,
            }
        });
        return res;

    } catch (err: any) {

        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
            dispatch(signOut());
        };

        return rejectWithValue(err.response.data);
    }
});
export const getFiles = createAppAsyncThunk('orders/getFiles', async function (values: any, { rejectWithValue, getState, dispatch }) {
    try {
        const user: User = getState().auth.user;
        const { client_id, downloadType } = values;
        const endpointType: string = "get";

        // handleDownload singleFile
        if (downloadType === 'singleFile') {
            let { path } = values;
            let { data } = await getPresignedUrl({ user: { id: client_id, accessToken: user.accessToken }, path, endpointType });
            let presignedUrl = data.presigned_url

            return { presignedUrl, downloadType };
        }

        // Calling getFile directly for array paths.
        let { paths } = values;

        let presignedUrls: Array<string> = [];

        presignedUrls = await Promise.all(paths.map(async (path: string) => {
            let res = await getPresignedUrl({ user: { id: client_id, accessToken: user.accessToken }, path, endpointType });
            return res.data.presigned_url;
        }));

        return { presignedUrls, downloadType };
    } catch (err: any) {

        if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
            if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                dispatch(signOut());
            };
            dispatch(signOut());
        };

        return rejectWithValue(err.response.data);
    }
});
