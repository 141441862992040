import React from 'react';
import { Link } from 'react-router-dom';

function Header(props: any) {

    const { title } = props;

    return (
            <div className="breadcrumb-header justify-content-between">
                <div className="left-content">
                    <span className="main-content-title mg-b-0 mg-b-lg-1">{title}</span>
                    <div className="justify-content-center mt-2">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <Link to={'/dashboard/orders'} className="breadcrum-item tx-15">Inicio</Link>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
    )
}

export default Header;
