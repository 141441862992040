import React, { Fragment } from "react";

import { Modal } from 'react-bootstrap';

function Confirmation(props: any) {
    const { showModal, title, content, onSubmit, onDismiss, firstButton } = props;
    return (
      <Fragment>
        <Modal show={showModal ? true : false} aria-labelledby="contained-modal-title-vcenter" centered>
          <div className='modal-header'>
            <button className="btn btn-close" onClick={function () { onDismiss() }}>
              x
            </button>
          </div>
          <div className='modal-body'>
            <div className="tx-center ">
              <i className="icon ion-ios-warning tx-100 tx-warning lh-1 mg-t-20 d-inline-block"></i>{" "}
              <h4 className="tx-semibold mg-b-20">{title}</h4>{" "}
              <p className="mg-b-20 mg-x-20">
                {content}
              </p>
              <button className="btn ripple btn-success pd-x-25" type="button" onClick={function () { onSubmit() }}>
                {firstButton}
              </button>
              {" "}
              <button className="btn ripple btn-secondary btn pd-x-15" type="button" onClick={function () { onDismiss() }}>
                Cancelar
              </button>
              {" "}
            </div>
          </div>
        </Modal>
      </Fragment>
    )
}

export default Confirmation;
