import React, { Fragment } from 'react';
import { useAppSelector } from '../../app/hooks/redux';
import { selectAuth } from '../../app/store/slice/auth';

import Header from '../../components/Home/Header'
import Landing from '../../components/Home/Landing';
import Selecting from '../../components/Home/Selecting';
import Footer from '../../components/Home/Footer';

function Home() {
  const { user } = useAppSelector(selectAuth);
  return (
    <Fragment>
        <Header user={user}/>
        <Landing />
        <Selecting />
        <Footer />
    </Fragment>
  );
};

export default Home;
