
import React, { useState } from 'react';

import Header from '../../Dashboard/Header';
import OrdersForm from './OrdersForm';

import Rocket from '../../../assets/img/png/brand/rocket.png';
import CutMetal from '../../../assets/img/png/brand/corte-metal.png';
import CutTube from '../../../assets/img/png/brand/corte-tubo.png';
import ImgSelector from './ImgSelector';

function OrdersFormComponent(props: any) {
  const { onSubmit } = props;

  const [cutType, setCutType] = useState("metal");

  function handleOnClick (cutType: any) {
    
    setCutType(cutType);

  }

  return (
    <div>
      <Header title="CREA TU COTIZACIÓN"/>
        <div className="container-orders" style={{ backgroundColor: "#CCCCCC"}}>
          <div className="child-orders-img">
            <ImgSelector handleOnClick={handleOnClick} cutType={cutType} />
          </div>
          <div className="child-orders-img">
            <OrdersForm onSubmit={onSubmit} cutType={cutType} /> 
          </div>
          <div className="align-self-end child-orders-img">  
            <img src={Rocket}/> 
          </div>
          <div className="child-orders-img align-self-center"> 
            <img src={cutType === "tube" ? CutTube : CutMetal} />
          </div>
        </div>
    </div>
  );
};


export default OrdersFormComponent;
