import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../Dashboard/Header';

function EmptyInfo (props: any) {
    
    const { img } = props;

    return (
        <div>
            <Header title="COTIZACIONES"/>
            <div className="card">
                <div className="d-flex justify-content-center p-1">
                    <div className="text-center">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h1 className="h1">¡Bienvenido!</h1>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <img className="img-fluid" alt="not found" src={img} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col p-3">
                                    <h4>Aún no tienes cotizaciones.</h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Link to={'/dashboard/orders/create'} className="btn btn-primary btn-lg shadow p-3">Crea tu cotización</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default EmptyInfo;
