import React from 'react';

import { useForm } from 'react-hook-form';

import Input from '../Form/Input';

import { requireField, errorTypes, minNum } from '../../utils/formValidations';

function CreateBalanceForm (props: any) {
  
  const { createPaymentIntent } = props;
  const { handleSubmit, register, formState: { errors } } = useForm();
  
  return (
    <form className="pt-5 pb-5" onSubmit={handleSubmit(createPaymentIntent)}>
      <Input 
          type={"number"} 
          name={"amount"} 
          validation={{ required: requireField, min: minNum }} 
          label={"Ingrese un saldo"} 
          placeholder="Saldo" 
          errorMessages={[{ type: errorTypes.required, message: 'Ingrese un saldo' }, { type: errorTypes.min, message: 'Saldo inválido' }]} 
          register={register} 
          errors={errors} 
        />
      <button className="btn btn-success mt-5">Agregar Saldo</button>
    </form>
  );
};

export default CreateBalanceForm;
