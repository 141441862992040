import { createAsyncThunk } from '@reduxjs/toolkit';
import { cleanState } from '.';
import { SignInPayload, SignUpPayload } from '../../../../models/auth';
import { cognitoSignIn, cognitoSignUp, cognitoConfirmRegistration,
    cognitoResendConfirmation, cognitoSendForgotPasswordCode, cognitoSignOut,
    cognitoChangePassword, cognitoForgotPassword
} from '../../../../utils/cognito';

export const signIn = createAsyncThunk('auth/signIn', async function (values: SignInPayload, { rejectWithValue }) {
    try {
        const res = await cognitoSignIn(values);
        return res;
    } catch (err: any) {
        // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.message);
    }
});

export const signUp = createAsyncThunk('auth/signUp', async function (values: SignUpPayload, { rejectWithValue }) {
    try {
        const res = await cognitoSignUp(values);
        return res;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
});

export const confirmRegistration = createAsyncThunk('auth/confirmRegistration', async function (values: any, { rejectWithValue }) {
    try {
        const res = await cognitoConfirmRegistration(values);
        return res;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
});

export const resendConfirmation = createAsyncThunk('auth/resendConfirmation', async function (email: any, { rejectWithValue }) {
    try {
        const res = await cognitoResendConfirmation(email);
        return res;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
});

export const changePassword = createAsyncThunk('auth/changePassword', async function (values: any, { rejectWithValue }) {
    try {
        const res = await cognitoChangePassword(values);
        return res;
    } catch (err: any) {
        // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.message);
    }
});

export const sendForgotPasswordCode = createAsyncThunk('auth/sendForgotPasswordCode', async function (email: any, { rejectWithValue }) {
    try {
        const res = await cognitoSendForgotPasswordCode(email);
        return res;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
});

export const forgotPassword = createAsyncThunk('auth/forgotPassword', async function (values: any, { rejectWithValue }) {
    try {
        const res = await cognitoForgotPassword(values);
        return res;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
});

export const signOut = createAsyncThunk('auth/signOut', async function (_, { rejectWithValue, dispatch }) {
    try {
        const res = await cognitoSignOut();
        dispatch(cleanState());
        return res;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
});
