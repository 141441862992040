import { createAppAsyncThunk } from '../../../hooks/redux';
import cutDesignApi from '../../../../apis/cutDesignApi';
import { User } from '../../../../models/auth';

import { signOut } from '../auth/asyncTasks';

import { UNAUTHORIZED_ERROR_TYPE, UNAUTHORIZED_ERROR_MESSAGE } from '../../../../utils/types';

export const fetchClientSecretPaymentIntent = createAppAsyncThunk('payments/fetchClientSecretPaymentIntent',
    async function (amount: any, { rejectWithValue, getState, dispatch }) {

        try {
            const user: User = getState().auth.user;
    
            const res = await cutDesignApi.post(`/payments/intent`, { amount }, { 
                headers: {
                    Authorization: user.accessToken,
                }
             });
            return res;
        } catch (err: any) {
            
            if (err.response.status === UNAUTHORIZED_ERROR_TYPE) {
                if (err.response.data.error_code === UNAUTHORIZED_ERROR_MESSAGE) {
                    dispatch(signOut());
                };
                dispatch(signOut());
            };
            
            return rejectWithValue(err.response.data);
        }
});
