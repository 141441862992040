import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { PaymentsState } from '../../../../models/payments';
import { fetchClientSecretPaymentIntent } from './asyncTasks';

const initialState: PaymentsState = {
  payment: undefined,
  status: 'idle',
  message: ''
};

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setStripeError: function(state, action: PayloadAction<any>) {
      state.status = "failed";
      state.message = action.payload;
    },
    cleanPaymentIntent: function(state) {
      state.status = "idle";
      state.message = "";
      state.payment = undefined;
    }
  },
  extraReducers: function (builder) {
    builder
      .addCase(fetchClientSecretPaymentIntent.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(fetchClientSecretPaymentIntent.fulfilled, function (state, action){
        state.payment = action.payload?.data;
        state.status = "fulfilled";
      })
      .addCase(fetchClientSecretPaymentIntent.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
  },
});

export const { setStripeError, cleanPaymentIntent } = paymentsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export function selectPayments (state: RootState) {return state.payments};

export default paymentsSlice.reducer;
