import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import LoginForm from './LoginForm';

import './style.css';

function LoginComponent(props: any) {

    const { onSubmit } = props;

    return (

        <div className="row">
            <div className="d-none d-sm-block page col background-image-sign-in"></div>
            <div className="page col card card-sigin-main justify-content-center">
                <div className="container">
                    <div className="card d-flex flex-column justify-content-end">
                        <div className="d-flex m-4">
                            <Link to="#">
                                <img
                                    src={require("../../../assets/img/brand/favicon.png")}
                                    className="sign-favicon ht-40"
                                    alt="logo"
                                />
                            </Link>
                        </div>
                        <div className="main-card-signin">
                            <div className="d-flex flex-column">
                                <div className="align-self-center">
                                    <h1 className="font-weight-bold text-muted display-5">¿Ya estás registrado?</h1>
                                    <div className="d-flex justify-content-center">
                                        <p className="h1 align-self-center font-weight-semibold text-custom-blue m-4">¡Claro que sí!</p>
                                    </div>
                                </div>
                                <div className="d-inline-flex justify-content-center">
                                    <div className="mb-2 border-bottom-0">
                                        <div className="">
                                            <div className="w-100 p-2 d-inline-flex justify-content-center">
                                                <div className="sign-in-border d-inline-flex justify-content-center">
                                                    <h6>Iniciar sesión</h6>
                                                </div>
                                            </div>
                                            {/* {err && <Alert variant="danger">{err}</Alert>} */}
                                            <LoginForm onSubmit={onSubmit} />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center justify-content-center">
                                    <p className="display-5 text-custom-green font-weight-bold m-4 wd-50p">Aún no, pero quiero mi corte</p>
                                    <img
                                        src={require("../../../assets/img/png/flecha-registro.png")}
                                        className="ht-200"
                                        alt="footer-img"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginComponent;
