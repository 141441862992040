import React, { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';

import PaymentsContainer from '../../../containers/Dashboard/Payments';

function Payments () {

    return (
        <Fragment>   
            <Routes>
                <Route path="/" element={<PaymentsContainer />} /> 
            </Routes>
        </Fragment>
    );
};

export default Payments;
