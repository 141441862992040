import React from 'react';
import { useAppSelector } from '../../../../app/hooks/redux';
import { selectUsers } from '../../../../app/store/slice/user';

import Profile from '../../../../components/Profile';
import Placeholder from '../../../../components/Placeholder';

function UsersDetail () {
    
    const { user } = useAppSelector(selectUsers);

    return (

        !user ?
            <Placeholder />
        :
            <Profile user={user} /> 
    );
}
export default UsersDetail;
