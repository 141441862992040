import React from "react";

function DetailTable(props: any) {

  const { cut_type, width, length, thickness, material_type, notes, status, quotation, cut_count, material_count } = props;

  return (
    <div>
      <table className="table table-invoice table-bordered">
        <thead>
          <tr>
            <th className="wd-20p">Tipo de corte</th>
            <th className="tx-right">Material</th>
            <th className="tx-right">Espesor</th>
            <th className="tx-right">Dimensiones</th>
            <th className="tx-right">Material Pzs</th>
            <th className="tx-right">Corte Pzs</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="tx-right">{cut_type}</td>
            <td className="tx-right">{material_type}</td>
            <td className="tx-right">{thickness}</td>
            <td className="tx-right">{length} x {width}</td>
            <td className="tx-right">{cut_count}</td>
            <td className="tx-right">{material_count}</td>
          </tr>
          <tr>
            <td className="valign-middle" colSpan={2} rowSpan={4}>
              <div className="invoice-notes">
                <label className="main-content-label tx-13">
                  Notas
                </label>
                <p className="tx-right ">
                  {notes}
                </p>
              </div>

            </td>
          </tr>
          <tr>
            <td className="tx-right"> <strong></strong></td>
            <td className="tx-right" colSpan={2}>
            </td>
          </tr>
          {
            ["awaiting_payment", "awaiting_quotation"].includes(status) ?
              <tr>
                <td className="tx-right tx-uppercase tx-bold tx-inverse">
                  Revise estatus de orden.
                </td>
                <td className="tx-right" colSpan={2}>
                  <h4 className="tx-bold">$ __.__</h4>
                </td>
              </tr>
              :
              <>
                <tr>
                  <td className="tx-right tx-uppercase tx-bold tx-inverse">
                    Total
                  </td>
                  <td className="tx-right" colSpan={2}>
                    <h4 className="tx-bold">{quotation ? quotation?.price : '___.___'}</h4>
                  </td>
                </tr>
              </>
          }

        </tbody>
      </table>
    </div>
  );
};

export default DetailTable;
