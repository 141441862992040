import React from 'react';
import { useAppDispatch } from '../../../app/hooks/redux';
import { signOut } from '../../../app/store/slice/auth/asyncTasks';

import SidebarComponent from '../../../components/Dashboard/Sidebar';

function Sidebar () {

    const dispatch = useAppDispatch();

    function logout () {
        dispatch(signOut());
    };

    return (
        <SidebarComponent logout={logout} />
    );
};

export default Sidebar;
