import React from 'react';

import Header from '../../Dashboard/Header';
import OrdersQuickForm from './OrdersQuickForm';


function QuickOrdersFormComponent(props: any) {
  const { onSubmit, order } = props;

  return (
    <div>
      <Header title="CREA TU COTIZACIÓN"/>
      <div className="row">
        <div className="col col-lg-12 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="main-content-label mg-b-5">Creación de cotización rapida.</div>
              <OrdersQuickForm onSubmit={onSubmit} order={order}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default QuickOrdersFormComponent;
