import React from 'react';

import Header from '../Dashboard/Header';
import CreateBalanceForm from './CreateBalanceForm';
import BalanceDetail from './BalanceDetail';
import PaymentForm from '../Payments/PaymentForm';

function BalanceComponent (props: any) {

    const { onSubmit, createPaymentIntent, amount, payment } = props;

    return (
        <div>
            <Header title="SALDO"/>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card">
                        {
                            !payment ?
                                <div className="card-body p-5">
                                    <div className="main-content-label mg-b-5">Ingresa la cantidad</div>
                                    <CreateBalanceForm createPaymentIntent={createPaymentIntent} />
                                </div>
                            :
                                <div className="card-body p-5">
                                    <div className="main-content-label mg-b-5">Ingresa la cantidad</div>
                                    <PaymentForm onSubmit={onSubmit} total={amount} />
                                </div> 
                        }
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <BalanceDetail amount={amount}/>
                </div>
            </div>
        </div>
    );
};

export default BalanceComponent;
