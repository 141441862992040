import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks/redux';
import { confirmRegistration, resendConfirmation } from '../../app/store/slice/auth/asyncTasks';
import { selectAuth } from '../../app/store/slice/auth';

import ConfirmationComponent from '../../components/Auth/Confirmation';

function Confirmation () {

    const dispatch = useAppDispatch();
    const { user } = useAppSelector(selectAuth);

    function onSubmit (formValues: any) {
        const confirmationValues: Object = {
            email: user.email,
            confirmationCode: formValues.confirmationCode,
        }
        dispatch(confirmRegistration(confirmationValues));
    };

    function onResendConfirm () {
        dispatch(resendConfirmation(user.email));
    };

    return (
        <ConfirmationComponent onSubmit={onSubmit} onResendConfirm={onResendConfirm} />
    );
};

export default Confirmation;
