import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppThunkDispatch, useAppSelector } from '../../../../app/hooks/redux';
import { selectUsers } from '../../../../app/store/slice/user';
import { updateUser } from '../../../../app/store/slice/user/asyncTasks';

import UserUpdateComponent from '../../../../components/Users/UserUpdate';

function UserUpdate () {

    const navigate = useNavigate();
    const dispatchThunk = useAppThunkDispatch();
    const { user } = useAppSelector(selectUsers);

    async function onSubmit(formValues: any) {
        await dispatchThunk(updateUser(formValues));
        navigate("/dashboard/profile")
    };

    return <UserUpdateComponent user={user} onSubmit={onSubmit} />
};

export default UserUpdate;
