import React, { Fragment } from 'react';

import {
    useTable,
    useSortBy,
    useGlobalFilter,
    usePagination,
} from 'react-table';

function BankAccountsTable(props: any) {
    const { columns, data } = props;

    const tableInstance = useTable(
        {
            columns: columns,
            data: data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps, // table props from react-table
        headerGroups, // headerGroups, if your table has groupings
        getTableBodyProps, // table body props from react-table
        prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
        state,
        page, // use, page or rows
    }: any = tableInstance;

    const { globalFilter }: any = state;

    return (
        <Fragment>
            {/* <div className='row'>
                <div className="col-sm-12"> */}
                    <div className='card'>
                        <div className='card-header'>
                            <h4 className="card-title">Donde transferir</h4>
                        </div>
                        <div className="card-body pt-0">
                            <div className="table-responsive">
                                <>
                                    <table {...getTableProps()} className="table table-bordered text-nowrap mb-0">
                                        <thead>
                                            {headerGroups.map((headerGroup: any) => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map((column: any) => (
                                                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column.className}>
                                                            <span className="tabletitle">{column.render("Header")}</span>
                                                            <span>
                                                                {column.isSorted ? (
                                                                    column.isSortedDesc ?
                                                                        (<i className="fa fa-angle-down"></i>)
                                                                        :
                                                                        (<i className="fa fa-angle-up"></i>)
                                                                )
                                                                    :
                                                                    ("")}
                                                            </span>
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {page.map((row: any) => {
                                                prepareRow(row);
                                                return (
                                                    <tr className="text-center" {...row.getRowProps()}>
                                                        {row.cells.map((cell: any) => {
                                                            return (
                                                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </>
                            </div>
                        </div>
                    </div>
                {/* </div>
            </div> */}
        </Fragment>
    );
};

export default BankAccountsTable;
