import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { OrdersState } from '../../../../models/orders';
import { fetchOrders, fetchOrder, createOrder, updateOrder, getFiles } from './asyncTasks';

const initialState: OrdersState = {
  orders: [],
  ordersHistory: [],
  reception_image_urls: [],
  delivery_image_urls: [],
  order: undefined,
  status: 'idle',
  message: ''
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    getOrdersHistory: function(state) {
      state.ordersHistory = state.orders.filter( function (order: any) { return order.status === 'delivered' });
    }
  },
  extraReducers: function (builder) {
    builder
      .addCase(fetchOrders.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(fetchOrders.fulfilled, function (state, action){
        state.orders = action.payload.data.items;
        state.status = "fulfilled";
      })
      .addCase(fetchOrders.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Fetch order
      .addCase(fetchOrder.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(fetchOrder.fulfilled, function (state, action){
        state.order = action.payload.data;
        state.status = "fulfilled";
      })
      .addCase(fetchOrder.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Create order
      .addCase(createOrder.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(createOrder.fulfilled, function (state, action){
        state.order = action.payload.data;
        state.status = "fulfilled";
      })
      .addCase(createOrder.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Update Order
      .addCase(updateOrder.pending, function(state, action){
        state.status = "loading";
      })
      .addCase(updateOrder.fulfilled, function (state, action){
        state.order = action.payload.data;
        state.status = "fulfilled";
      })
      .addCase(updateOrder.rejected, function(state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
      // Get Files
      .addCase(getFiles.pending, function (state, action){
        state.status = "loading";
      })
      .addCase(getFiles.fulfilled, function (state, action) {
        if(action.payload.downloadType === 'reception_file_paths') {
          state.reception_image_urls = action.payload.presignedUrls;
        } else if (action.payload.downloadType === 'delivery_file_paths'){
          state.delivery_image_urls = action.payload.presignedUrls;
        }
        state.status = "fulfilled";
      })
      .addCase(getFiles.rejected, function (state, action) {
        state.status = "failed"
        state.message = action.payload;
      })
  },
});

export const { getOrdersHistory } = ordersSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export function selectOrders (state: RootState) {return state.orders};

export default ordersSlice.reducer;
