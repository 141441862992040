import React, { Fragment } from 'react';

import FullCalendar, { LocaleSingularArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';

import Header from '../../Dashboard/Header';

export function OrdersCalendar(props: any) {

  const { quotations, height, isFromCalendarContainer } = props;

  const show = isFromCalendarContainer;

  const INITIAL_EVENTS = quotations.map(function (quotation: any, index: any) {
    if (index === 0) {
      return { title: ` Quick Time ${quotation.price}`, start: quotation.due_date, classNames: ['w-100', 'background-quick-color'] }
    }
    if (index === 1) {
      return { title: ` Plus Time ${quotation.price}`, start: quotation.due_date, classNames: ['w-100', 'background-plus-color'] }
    }
    return { title: ` Normal ${quotation.price}`, start: quotation.due_date, classNames: ['w-100', 'background-normal-color'] }
  });

  function renderEventContent(eventInfo: any) {
    return (
      <div className={`${eventInfo.event.classNames[0]} ${eventInfo.event.classNames[1]}`}>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </div>
    );
  }

  return (

    <Fragment>
      {
        show ?
          <Header title="CALENDARIO" />
          :
          null
      }
      <div className="pd-b-0 main-content-calendar pt-0">
            <div className="card">
              {
                show ?
                  <div className="card-header">
                    <h3 className="card-title">
                      Seleccione una cotización
                    </h3>
                  </div>
                  :
                  null
              }
              <div className="card-body">
                    <div id="calendar2">
                      <FullCalendar
                        plugins={[
                          dayGridPlugin,
                          timeGridPlugin,
                          interactionPlugin,
                        ]}
                        headerToolbar={{
                          left: "",
                          center: "title",
                          right: "",
                        }}
                        initialView="dayGridMonth"
                        selectMirror={true}
                        dayMaxEvents={true}
                        locale={esLocale as LocaleSingularArg}
                        initialEvents={INITIAL_EVENTS}
                        eventContent={renderEventContent}
                        height={height}
                        
                      />
                    </div>
              </div>
            </div>
      </div>
    </Fragment>
  );
};

export default OrdersCalendar;
