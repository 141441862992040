import React, { useState } from 'react';

import { useStripe, useElements } from '@stripe/react-stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';

import Confirmation from '../../Modal/Confirmation';

function PaymentForm(props: any) {

  const [showModal, setShowModal] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const { onSubmit, total } = props;

  function handleOnSubmit(e: any) {

    e.preventDefault();
    setShowModal(true);
  }

  function handleOnSubmitModal() {
    onSubmit({ stripe, elements });
  }

  function onDismiss() {
    setShowModal(false);
  };

  return (
    <form className="pt-5 pb-5" id="payment-form" onSubmit={handleOnSubmit}>
      <PaymentElement id="payment-element" />
      <dl className="dlist-align m-5">
        <h3>Total:</h3>
        <dd className="text-end ms-auto tx-40">
          MXN
          <strong>${total}</strong>
        </dd>
      </dl>
      <button className="btn btn-primary" id="submit" >
        <span id="button-text">
          {"Confirmar pago"}
        </span>
      </button>
      <Confirmation
        showModal={showModal}
        title={'¿Estás seguro que quieres proceder?'}
        content={'Esta acción no puede ser revertida'}
        firstButton={'Pagar'}
        onDismiss={onDismiss}
        onSubmit={handleOnSubmitModal}
      />
    </form>
  );
};

export default PaymentForm;
