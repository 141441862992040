import React, { Fragment } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { NavLink, Link } from 'react-router-dom';

function Sidebar(props: any) {

    const { logout } = props;

    return (
        <Fragment>
            <div className="sticky">
                <aside className="app-sidebar">
                    <Scrollbars className="hor-scroll" style={{ position: "absolute" }}>
                        <div className="main-sidebar-header active">
                            <NavLink className="header-logo active" to={`${process.env.PUBLIC_URL}/dashboard/orders`}>
                                <img
                                    src={require("../../assets/img/brand/logo.png")}
                                    className="main-logo  desktop-logo"
                                    alt="logo"
                                />
                            </NavLink>
                        </div>
                        <div className="main-sidemenu">
                            <div className="slide-left disabled" id="slide-left">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#7b8191"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                                </svg>
                            </div>

                            <ul className="side-menu">
                                <li className="side-item side-item-category">MAIN</li>
                                <li className="slide">
                                    <Link className="side-menu__item" to={'/dashboard/orders'}>
                                        <svg xmlns="http://www.w3.org/2000/svg"  className="side-menu__icon" width="24" height="24" viewBox="0 0 640 512"><path d="M320 144c-53.02 0-96 50.14-96 112 0 61.85 42.98 112 96 112 53 0 96-50.13 96-112 0-61.86-42.98-112-96-112zm40 168c0 4.42-3.58 8-8 8h-64c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h16v-55.44l-.47.31a7.992 7.992 0 0 1-11.09-2.22l-8.88-13.31a7.992 7.992 0 0 1 2.22-11.09l15.33-10.22a23.99 23.99 0 0 1 13.31-4.03H328c4.42 0 8 3.58 8 8v88h16c4.42 0 8 3.58 8 8v16zM608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zm-16 272c-35.35 0-64 28.65-64 64H112c0-35.35-28.65-64-64-64V176c35.35 0 64-28.65 64-64h416c0 35.35 28.65 64 64 64v160z"/></svg>
                                        <span className="side-menu__label">Cotizaciones</span>
                                    </Link>
                                </li>
                                <li className="slide">
                                    <Link className="side-menu__item" to={'/dashboard/orders/history'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 384 512"><path d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48z"/></svg>
                                         <span className="side-menu__label">Historial de pedidos</span>
                                    </Link>
                                </li>
                                <li className="slide">
                                    <Link className="side-menu__item" to={'/dashboard/balance'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 512 512"><path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"/></svg>
                                        <span className="side-menu__label">Agregar saldo</span>
                                    </Link>
                                </li>
                                <li className="slide">
                                    <Link className="side-menu__item" to="#" onClick={function () { logout() }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 448 512"><path d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"/></svg>
                                        <span className="side-menu__label">Cerrar Sesión</span>
                                    </Link>
                                </li>    
                            </ul>
                            <div className="slide-right" id="slide-right">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#7b8191"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                                </svg>
                            </div>

                        </div>
                    </Scrollbars>
                </aside>
            </div>
        </Fragment>
    );
};

export default Sidebar;
