import React, { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';

import OrdersContainer from '../../../containers/Dashboard/Orders';
import OrdersHistory from '../../../containers/Dashboard/Orders/OrderHistory';
import OrderCreation from '../../../containers/Dashboard/Orders/OrderCreation';
import QuickOrdersCreation from '../../../containers/Dashboard/Orders/QuickOrderCreation';
import OrdersDetail from '../../../containers/Dashboard/Orders/OrderDetail';
import OrdersCalendarPayment from '../../../containers/Dashboard/Orders/OrderCalendar';
import OrdersQuotations from '../../../containers/Dashboard/Orders/OrderQuotations';
import OrderPayment from '../../../containers/Dashboard/Orders/OrderPayment';

function Orders () {

    return (
        <Fragment>   
            <Routes>
                <Route path="/" element={<OrdersContainer />} /> 
                <Route path="/history" element={<OrdersHistory/>} /> 
                <Route path="/create" element={<OrderCreation />} />
                <Route path="/:id/quick-creation" element={<QuickOrdersCreation />} />
                <Route path="/:id/detail" element={<OrdersDetail />} />
                <Route path="/:id/calendar" element={<OrdersCalendarPayment />} />
                <Route path="/:id/quotations" element={<OrdersQuotations />} />
                <Route path="/:id/quotations/:quotationId/payment" element={<OrderPayment />} />
            </Routes>
        </Fragment>
    );
};

export default Orders;
