import React from 'react';
//import { Link } from 'react-router-dom';
//import "./styles.css";

import scroll from "../../assets/img/png/brand/scroll-icon.png";

function Landing() {
  return (
    <div className= "scrollable-section-home align-home" style={{ backgroundColor: '#b5ed36'}}>
      <div className="container text-center">
        <div className="row">
          <div className="col">
            <h1 className="mb-3 font-weight-semibold text-white tx-50">
                    ¡Bienvenido! <br></br> ¿Que vamos a cortar hoy?
            </h1>
            <img className="ht-50 mt-5" src={scroll}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
