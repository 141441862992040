import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../Dashboard/Header';
import DetailTable from '../../Table/Detail';
import FilesSection from './FileSection';


function OrderDetail (props: any) {

  const { handleChangeStatus, reception_image_urls, delivery_image_urls, quotation } = props;
  const { order_id, order_custom_id, client_email, client_id, status, creation_date, width, thickness, length, material_type, cut_type, notes, cut_count, material_count } = props.order;
  
  return (
    <div>
      <Header title="DETALLE DE LA COTIZACIÓN"/>
      <div className="row row-sm">
        <div className="col col-12">
          <div className="card custom-card">
            <div className="card-body">
              <div className="d-lg-flex">
                <h6 className="main-content-label mb-1">
                  <span className="d-flex mb-4">
                    <Link to={"/dashboard/orders"}>
                      <img
                        src={require("../../../assets/img/brand/favicon.png")}
                        className="sign-favicon ht-40"
                        alt="logo"
                      />
                    </Link>
                  </span>
                </h6>
                <div className="ms-auto">
                  <p className="mb-1">
                    <span className="font-weight-bold">Cliente: </span>{client_email}
                  </p>
                </div>
              </div>
              <div className="row row-sm">
                <div className="col col-lg-6">
                  <p className="h3">ID Cotización:</p>
                  <address  className="h4 font-weight-bold">
                  {order_custom_id}
                  </address>
                </div>
                <div className="col col-lg-6 text-end">
                  <p className="h3">Detalles generales:</p>
                  <div>
                    <p className="mb-1">
                      <span className="h4">Estatus:</span>
                    </p>
                    <span className="badge badge-success-transparent h5">{status}</span>
                  </div>
                  <br />
                  <div>
                    <p className="mb-1">
                      <span className="font-weight-bold">Fecha de creación:</span>
                    </p>
                    <address className="h5">{creation_date.replace('T', ' ')}</address>
                  </div>
                  <div>
                    <p className="mb-1">
                      <span className="font-weight-bold">Tipo de Pago:</span>
                    </p>
                    <address className="h5">{ quotation?.payment_type ? quotation.payment_type : ''}</address>
                  </div>
                </div>
              </div>
              <div className="table-responsive mg-t-40">
                <DetailTable
                quotation={quotation}
                status={status}
                cut_type={cut_type}
                material_type={material_type}
                thickness={thickness}
                width={width}
                length={length}
                cut_count={cut_count}
                material_count={material_count}
                notes={notes}/>
              </div>
            </div>
            <div className="card-footer text-end">
              <h3 className="m-4">Acciones: </h3>
              {
                  status !== "awaiting_payment" ?
                    null
                  :
                  <Fragment>
                    <Link  
                      to={`/dashboard/orders/${order_id}/quotations`}
                      className="btn ripple btn-info  mb-1 me-2"
                    >
                      <i className="far fa-credit-card me-1"></i> Cotizaciones
                    </Link>
                    <Link  
                      to={`/dashboard/orders/${order_id}/calendar`}
                      className="btn ripple btn-primary  mb-1 me-2"
                    >
                      <i className="far fa-calendar me-1"></i> Calendario
                    </Link>
                  </Fragment>
              }
              {
                ["payed", "in_progress", "done", "delivered", "canceled"].includes(status) ?
                  null
                :
                  <button
                    type="button"
                    className="btn ripple btn-danger mb-1 me-2"
                    onClick={function () { handleChangeStatus("canceled") }}
                  >
                    <i className="fa fa-ban"></i> Cancelar
                  </button>
              }
              {/* <button
                type="button"
                className="btn ripple btn-info mb-1 me-2"
              >
                <i className="fe fe-printer me-1"></i> Imprimir
              </button> */}
            </div>
            <div className="border m-5"/>
              <FilesSection order={props.order} 
                reception_image_urls={reception_image_urls} 
                delivery_image_urls={delivery_image_urls} 
                // handleDownload={handleDownload} 
                />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetail;
