import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppThunkDispatch } from '../../../../app/hooks/redux';
import { createOrder } from '../../../../app/store/slice/orders/asyncTasks';
import { selectUsers } from '../../../../app/store/slice/user';

import OrdersFormComponent from '../../../../components/Orders/OrderCreation';



function OrdersCreate () {

    const navigate = useNavigate();
    const dispatch = useAppThunkDispatch();
    const { user } = useAppSelector(selectUsers);
    
    async function onSubmit (formValues: any) {
        await dispatch(createOrder({
            client_custom_id: user.customer_custom_id || user.id.slice(-12),
            client_email: user.email,
            client_company_name: user.company_name,
            cut_type: formValues.cut_type,
            cut_count: Math.round(parseInt(formValues.cut_count,10)),
            material_type: formValues.material_type,
            material_count: Math.round(parseInt(formValues.material_count, 10)),
            thickness_unit: formValues.thickness_unit,
            thickness: formValues.thickness,
            width: formValues.width,
            length: formValues.length,
            width_length_unit: formValues.width_length_unit,
            notes: formValues.notes,
            file: formValues.file
        }));
        navigate("/dashboard/orders");        
    }; 

    return (
        <div>
            <OrdersFormComponent onSubmit={onSubmit}/>      
        </div>
    )
}
export default OrdersCreate;
